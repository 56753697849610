/* ========================================================================blogs ==================================================================== */
.threeblogDiv {
  display: flex;
  justify-content: space-around;
}
.navIcon {
  margin-right: 100px !important;
  position: absolute;
  right: 0;
}
.featured-image {
  width: 60%;
  max-height: 400px;
  object-fit: cover;
}
.v3card2 {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 350px;
  height: 620px;
  background-color: #fff;
  border-radius: 5px;
}

.v3card {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 350px;
  height: 720px;
  background-color: #fff;
}
.v3image {
  width: 350px;
  height: 208px;
}
.v3blogbody {
  padding: 30px;
}
.read {
  color: #00a8c6;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 800;
  text-decoration: none !important;
}
.headv3blog {
  padding-top: 100px;
  padding-bottom: 100px;
  color: #fff;
  font-weight: 400;
  font-size: 70px;
  text-align: center;
}
.v3blogexcerpt {
  font-size: 14px;
  color: #50565e;
  overflow: hidden;
}
.slugbody {
  font-size: 18px;
  color: #50565e;
}
.slugtitle {
  font-size: 60px;
  color: #1c2733;
  text-transform: capitalize;
}
.slugimage {
  width: 100%;
  height: 80vh;
}
.spanscript {
  color: #00a8c6;
  font-size: 10px;
}
.v3blogmain {
  background-image: linear-gradient(90deg, #00a8c6 0%, #0f697b 100%);
  padding-top: 100px;
}
.v3blog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v3blog-tilte {
  margin-top: 30px;
  font-size: 23px;
  color: #1c2733;
  font-weight: 300;
}
.contactContainer {
  background-image: url(../images/contact.png);
  background-position: top;
  background-size: cover;
  height: 90vh;
  width: 100%;
}
.contactContainer h1 {
  position: relative;
  top: 40vh;
  color: #fff;
  margin-left: 50px;
}
.contactContainer h5 {
  position: relative;
  top: 40vh;
  color: #fff;
  margin-left: 50px;
}
.contactDetails {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  margin-top: 100px;
}
.imagebehindformKV {
  background: linear-gradient(
      to right,
      rgba(245, 45, 102, 0.7),
      rgba(247, 56, 31, 0.1)
    ),
    url("../images/sss.jpg");
  height: 80vh;
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: cover;
}

.formMainGridContactKV {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 80vh;
  width: 88vw;
  position: relative;
  top: -130px;
  left: 5%;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(26, 24, 24, 0.2);
}

.formheadingContactKV {
  color: #ff6041;
  padding-top: 150px;
  padding-left: 100px;
  padding-right: 60px;
  font-size: 18px;
}

.formheadingh1ContactKV {
  padding-left: 100px;
  font-size: 45px;
  padding-bottom: 60px;
}

.inputofFormContact {
  margin-left: 100px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  border: 1px solid #818a91;
  flex: 1;
  vertical-align: center;
  width: 450px;
  height: 47px;
  color: grey;
}

.inputofFormContact:focus {
  outline-color: #ff6041;
}

.inputofFormContact::placeholder {
  padding: 6px 16px;
}

.textareaContact {
  margin-left: 100px;
  padding-left: 16px;
  padding-top: 10px;
  color: grey;
  border: 1px solid #818a91;
}

.textareaContact:focus {
  outline-color: #ff6041;
}

.butonOfFormContactKV {
  margin-left: 100px;
  margin-top: 30px;
  background-color: #ff6041;
  color: #fff;
  width: 200px;
  height: 48px;
  border: none;
}

.butonOfFormContactKV:focus {
  outline: none;
}

.socialMediaContactKV {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.iconForLastContainerContact {
  padding-left: 25px;
  font-size: 25px;
}

.textForLastContainer {
  font-size: 25px;
  font-weight: 500;
  text-align: right;
}

.butonOfFormContactKV:focus {
  outline: none;
}

.socialMediaContactKV {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.iconfbliIn {
  margin-left: 10px;
  margin-right: 10px;
}

#fbicon {
  color: #1d119c;
}

#linicon {
  color: #0e76a8;
}

#insicon {
  color: rgb(221, 42, 123);
}

.onOfFormContactKV:focus {
  outline: none;
}

.socialMediaContactKV {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 25vh;
}

.officeMapContact {
  width: 90%;
  height: 60vh;
  margin-top: 12%;
}

.textareaContact {
  width: 450px;
  border-radius: 0;
}
.caseMain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}
.caseCard {
  max-width: 500px;
  height: 400px;
  background-color: #fff;
  box-shadow: 2px 0 50px #aaa;
  margin-top: 100px;
}
.webinarGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 50px;
  margin-top: 100px;
  margin-bottom: 100px;
}
.speakerCard {
  height: 300px;
  width: 250px;
  margin-bottom: 200px;
}
.speakerPic {
  width: 250px;
  height: 250px;
  border-radius: 6px;
  box-shadow: 2px 2px 6px #52b5ff, -2px -2px 6px #52b5ff;
}
.speakerName {
  width: 250px;
  min-height: 50px;
  background-color: #29abe2;
  border-radius: 6px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.formName {
  width: 550px;
  height: 50px;
  background-color: #29abe2;
  border-radius: 6px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;
  padding-left: 10px;
}
.speakerNamE {
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.speakerNAmE {
  font-size: 20px;
  color: #fff;
}
.formCard {
  min-height: 400px;
  width: 550px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.formContentweb {
  width: 100%;
  min-height: 300px;
  border-radius: 6px;
  box-shadow: 2px 2px 6px #52b5ff, -2px -2px 6px #52b5ff;
}
.speakGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.inputFont {
  height: 50px;
  width: 100%;
  background-color: #fcfcfc;
  outline: none;
  border: none;
  margin-top: 20px;
}

.inputFont::placeholder {
  color: #cccccc;
}
.select select {
  height: 50px;
  width: 100%;
  background-color: #fcfcfc;
  outline: none;
  border: none;
  margin-top: 20px;
}
.widthImage {
  height: 250px;
}
.impossibleNumber {
  font-size: 120px;
  color: #4d4d4d;
  font-weight: 900;
}
.blockHeght {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #4d4d4d;
  position: relative;
  top: 35px;
}
.gridForWebinar {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 2fr;
}
.backGrond {
  background-color: #f2f2f2;
  min-height: 50vh;
  width: 100%;
  padding-bottom: 50px;
}
.achorStyle {
  color: #fff;
}
.achorStyle:hover {
  color: #0093dc;
}
.webinarList{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 996px) {
  .deskView {
    display: none;
  }
  .gridForWebinar {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
  }
  .webinarGrid {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 50px;
  }
  .caseMain {
    grid-template-columns: 1fr;
  }
  .imagebehindformKV {
    background: linear-gradient(
        to right,
        rgba(245, 45, 102, 0.7),
        rgba(247, 56, 31, 0.1)
      ),
      url("../images/sss.jpg");
    height: 50vh;
    margin-top: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
  }
  .officeMapContact {
    width: 100%;
    height: 50vh;
    margin-top: 12%;
  }
  .navIcon {
    display: none;
  }
  .threeblogDiv {
    display: grid;
    justify-content: center;
  }
  .contactDetails {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    margin-top: 100px;
  }
  .formMainGridContactKV {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;
    width: 0;
    width: 100%;
    position: -webkit-sticky;
    left: 0;
    box-shadow: 10px 10px 10px rgba(26, 24, 24, 0.3);
  }
  .formheadingContactKV {
    padding-left: 0px;
    padding-right: 0px;
  }

  .formheadingh1ContactKV {
    padding-left: 0px;
  }

  .inputofFormContact {
    margin-left: 0px;
  }
  .textareaContact {
    margin-left: 0;
    padding-left: 0;
  }
  .formCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .formCard {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .conttitle {
    font-family: "Libre Baskerville", serif;
    font-size: 40px;
    font-weight: 500;
  }
  .featured-image {
    width: 95%;
    max-height: 400px;
    object-fit: cover;
  }
.webinarList{
  display: grid;
  grid-template-columns: 1fr;
}
}
@media screen and (max-width: 600px) {
  .containereded {
    padding-left: 1%;
    padding-right: 1%;
  }
  .widthImage {
    height: 250px;
    object-fit: cover;
    object-position: center;
  }
  .formCard {
    min-height: 400px;
    width: 100%;
  }
  .formName {
    width: 400px;
  }
  .speakGrid {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }
  .contactContainer h1 {
    left: 5px;
  }
  .contactContainer h5 {
    left: 5px;
  }
  .inputofFormContact {
    margin-left: 0px;
    width: 350px;
  }
  .textareaContact {
    width: 350px;
  }
  .contactContainer h1 {
    margin-left: 5px;
  }
  .contactContainer h5 {
    margin-left: 5px;
  }
  .webinarList{
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 400px) {
  .formCard {
    min-height: 400px;
    width: 350px;
    margin-left: 12px;
  }
  .formName {
    width: 350px;
  }
  .webinarList{
    display: grid;
    grid-template-columns: 1fr;
  }
}