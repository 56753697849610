@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
.slider img,
.card-image {
  height: 450px;
  object-fit: cover;
}

.pointer {
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
.dropdown:hover > .dropdown-menu {
  display: block;
}
.hideit {
  display: none;
}
.showit {
  display: block;
}
.buttonpp {
  width: 180px;
  height: 50px;
  background-color: #29e2c9;
  color: #fff;
  outline: none;
  transition: all 0.3s ease-in;
  border-radius: 100px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.buttonpp:hover {
  background-color: #ff3b73;
  color: #fff;
}

.backgroundchanger {
  background: linear-gradient(to right bottom, #03b6e6, #119c72);
  height: 35px;

  width: 35px;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
}
.logopic {
  transform: rotate(-45deg);
  align-self: center;
  width: 25px;
  height: 25px;
  padding: 1px;
}
.headingtext {
  color: #128d78;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.paragraphtext {
  color: #5c6771;
  font-family: aktiv-grotesk, Sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0.9rem;
}
.linktext {
  display: "flex";
  justify-content: "flex-start";
  align-content: "center";
  font-size: 15px;
  font-weight: 500;
  fill: #db3157;
  color: #db3157;
}
.subheadintext {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2em;
  color: #343352;
}
.texth1 {
  font-size: 50px;
  font-weight: 200 !important;
  color: #000;
  text-align: center;
}

.foc {
  border: none;
}

.foc:focus {
  outline: none;
}

.firstlandingdiv {
  background: url(/static/media/GRADMINI.5c34a71a.svg);

  background-size: cover;

  padding-top: 14%;
  min-height: 150vh;
  width: 100%;
}

.caradisplaymobile {
  display: none;
}

.headlanding {
  color: #ffffff;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 3px;
}

.paralanding {
  color: #ffffff;
  font-size: 22px;
}

.gridonehead {
  font-family: aktiv-grotesk, sans-serif;
  font-size: 26px;
  font-weight: 600;
  color: #ffffff;
}

.gridonepara {
  font-family: aktiv-grotesk, sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
  color: #ffffff;
}

.iconsauthen {
  font-size: 24px;
  color: #000;
}

/* ================================================form style========================================================= */

* {
  box-sizing: border-box;
}
/* 
body {
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  margin: -20px 0 50px;
} */

.h1text {
  font-weight: bold;
  margin: 0;
}

.h2text {
  text-align: center;
}

.paratextpara {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.spantext {
  font-size: 12px;
}

.dangertext {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.buttonpp2 {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.buttonpp2:active {
  transform: scale(0.95);
}

.buttonpp2:focus {
  outline: none;
}

.buttonpp2.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.formtext {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.inputtext {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.containered {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;

  max-width: 100%;
  height: 100vh;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.containered.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.containered.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  -webkit-animation: show 0.6s;
          animation: show 0.6s;
}

@-webkit-keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.containered.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #ff416c;
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.containered.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.containered.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.containered.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

/* =====================================input box style ========================================= */

/* body{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(-135deg, #c850c0, #0093dc);
} */

.wrapper {
  width: auto;
  background: #fff;
  padding: 5px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
}
.wrapper .input-data {
  height: 40px;
  width: 100%;

  position: relative;
}
.wrapper .input-data input {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid silver;
}
.input-data input:focus ~ label,
.input-data input:valid ~ label {
  transform: translateY(-20px);
  font-size: 15px;
  color: #0093dc;
}
.wrapper .input-data label {
  position: absolute;
  bottom: 10px;
  left: 0;
  color: grey;
  pointer-events: none;
  transition: all 0.3s ease;
}
.input-data .underline {
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
}
.input-data .underline:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #0093dc;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before {
  transform: scaleX(1);
  left: 0;
}
/* =====================================pricing card ================================= */
.nav-link {
  font-size: 20px;
}
.nav-tabs {
  margin-top: 30px;
}
.h1navlink {
  margin-top: 5%;
  font-family: "Poppins", sans-serif;
}
.underlined {
  display: inline-block;
  position: relative;
  line-height: 1;
  white-space: nowrap;
}
.h4para {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #5c6771;
  letter-spacing: 0.7px;
  line-height: 25px;
  margin-bottom: 100px;
}
.under {
  font-size: 60px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.underlined:before {
  content: "";
  display: block;
  position: absolute;
  height: 0.333em;
  right: -0.1em;
  bottom: 0;
  left: -0.1em;
  background-color: #8ff7e3;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-name: grow;
          animation-name: grow;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

/* =============================pricing======================================= */
.pricing-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
}
.pricingHead {
  background: url(/static/media/pricing.f1199223.svg);
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 70vh;
}

.Personal {
  flex: 1 1;
  max-width: 300px;
  background-color: #fff;
  margin: 20px 10px;

  box-shadow: 2px 2px 10px gray;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  color: #2d2d2d;
  transition: 0.3s linear;
}
.Personal:hover .pricing-card-header {
  box-shadow: 0 0 0 26em #29b864;
}
.pricing-card {
  border-radius: 10px;
}
.pricing-card:hover {
  transform: scale(1.2);
}
.Personal .pricing-card-header {
  background-color: #29b864;
  display: inline-block;
  color: #fff;
  padding: 12px 30px;
  border-radius: 0 0 20px 20px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.4s linear;
}
.Professional {
  flex: 1 1;
  max-width: 300px;
  background-color: #fff;
  margin: 20px 10px;
  box-shadow: 2px 2px 10px gray;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  color: #2d2d2d;
  transition: 0.3s linear;
}
.Professional:hover .pricing-card-header {
  box-shadow: 0 0 0 26em #2175f5;
}

.Professional .pricing-card-header {
  background-color: #2175f5;
  display: inline-block;
  color: #fff;
  padding: 12px 30px;
  border-radius: 0 0 20px 20px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.4s linear;
}
.Premium {
  flex: 1 1;
  max-width: 300px;
  background-color: #fff;
  margin: 20px 10px;
  box-shadow: 2px 2px 10px gray;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  color: #2d2d2d;
  transition: 0.3s linear;
}
.Premium:hover .pricing-card-header {
  box-shadow: 0 0 0 26em #f84848;
}

.Premium .pricing-card-header {
  background-color: #f84848;
  display: inline-block;
  color: #fff;
  padding: 12px 30px;
  border-radius: 0 0 20px 20px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.4s linear;
}
.Ultimate {
  flex: 1 1;
  max-width: 300px;
  background-color: #fff;
  margin: 20px 10px;
  box-shadow: 2px 2px 10px gray;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  color: #2d2d2d;
  transition: 0.3s linear;
}
.Ultimate:hover .pricing-card-header {
  box-shadow: 0 0 0 26em #ff5521;
}

.Ultimate .pricing-card-header {
  background-color: #ff5521;
  display: inline-block;
  color: #fff;
  padding: 12px 30px;
  border-radius: 0 0 20px 20px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.4s linear;
}

.price-personal {
  font-size: 70px;
  color: #29b864;
  margin: 30px 0;
  transition: 0.2s linear;
}

.price-personal sup,
.price-personal span {
  font-size: 22px;
  font-weight: 700;
}
.price-professional {
  font-size: 70px;
  color: #2175f5;
  margin: 30px 0;
  transition: 0.2s linear;
}

.price-professional sup,
.price-professional span {
  font-size: 22px;
  font-weight: 700;
}
.price-premium {
  font-size: 70px;
  color: #f84848;
  margin: 30px 0;
  transition: 0.2s linear;
}

.price-premium sup,
.price-premium span {
  font-size: 22px;
  font-weight: 700;
}
.price-ultimate {
  font-size: 70px;
  color: #ff5521;
  margin: 30px 0;
  transition: 0.2s linear;
}

.price-ultimate sup,
.price-ultimate span {
  font-size: 22px;
  font-weight: 700;
}

.pricing-card:hover,
.pricing-card:hover .price {
  color: #fff;
}

.pricing-card li {
  font-size: 16px;
  padding: 10px 0;
  text-transform: uppercase;
}

.Personal .order-btn {
  display: inline-block;
  margin-bottom: 40px;
  margin-top: 80px;
  border: 2px solid #29b864;
  color: #29b864;
  padding: 18px 40px;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.3s linear;
}

.Personal .order-btn:hover {
  background-color: #29b864;
  color: #fff;
}
.Premium .order-btn {
  display: inline-block;
  margin-bottom: 40px;
  margin-top: 80px;
  border: 2px solid #f84848;
  color: #f84848;
  padding: 18px 40px;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.3s linear;
}

.Premium .order-btn:hover {
  background-color: #f84848;
  color: #fff;
}
.Professional .order-btn {
  display: inline-block;
  margin-bottom: 40px;
  margin-top: 80px;
  border: 2px solid #2175f5;
  color: #2175f5;
  padding: 18px 40px;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.3s linear;
}

.Professional .order-btn:hover {
  background-color: #2175f5;
  color: #fff;
}
.Ultimate .order-btn {
  display: inline-block;
  margin-bottom: 40px;
  margin-top: 80px;
  border: 2px solid #ff5521;
  color: #ff5521;
  padding: 18px 40px;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.3s linear;
}

.Ultimate .order-btn:hover {
  background-color: #ff5521;
  color: #fff;
}
/* ======================================login =================================================================== */
.loginform {
  background-color: #e1e3e4;
  height: 100vh;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
  padding-top: 150px;
}
.textcenter {
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textcenterlink {
  font-weight: 600;
}
.loginborder {
  background-color: #fff;
  border-radius: 10px;
  width: 360px;
  padding: 10px;
  padding-top: 50px;
  height: 650px;
}
.registerborder {
  background-color: #fff;
  border-radius: 10px;
  width: 360px;
  padding: 10px;
  padding-top: 50px;
  height: 600px;
}
.headlogin {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
}
.imglogologin {
  margin-bottom: 30px;
  height: 100px;
}
@media screen and (max-width: 1100px) {
  .pricing-card {
    flex: 50% 1;
  }
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
@media screen and (max-width: 1026px) {
  .caradisplaymobile {
    display: block;
  }

  .caradisplaydesk {
    display: none;
  }
  .contain {
    flex: 50% 1;
  }
}

/* =============================================================================== */
/*                               PRODUCT CARD CSS                                  */
/* =============================================================================== */
.shopPageMainDiv {
  position: relative;
  top: 70px;
  background-color: #f8f8f8;
}
.filterContainerProduct {
  border-radius: 0px;
  background: #ffffff;
  box-shadow: inset -5px -5px 10px #a8a8a8, inset 5px 5px 10px #ffffff;
}
.productSectionShop {
  border-radius: 0px;
  background: #ffff;
  box-shadow: inset -5px -5px 10px #565656, inset 5px 5px 10px #ffffff;
  min-height: 150vh;
  margin-top: -0.76%;
  margin-left: 1px;
}
.iconOfProductCard {
  position: relative;
  top: -4px;
  width: 13%;
  height: 13%;
}
.previewProductCard {
  font-size: 22px;
  display: inline-block;
  color: #918e8e;
  margin-left: 7%;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.mainDivratingAndCostPCard {
  display: grid;
  grid-template-columns: 1fr 1fr;

  width: 100%;
}
.oldPriceandPercentOff {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.starRatingProductCard {
  padding-left: -70px;
}
.perPriceProductCard {
  padding-right: 0;
  padding-left: 18%;
}
.currentPriceProductCard {
  padding-left: 1%;
  font-size: 25px;
}
.ruppeeCurrency {
  font-size: 25px;
}

.wishandCartIconDivContainer {
  z-index: 1;
}
.productOptionIconKV {
  display: inline-block;
  position: relative;
  top: 36%;
  left: 25%;
}
.wishlistIconKV {
  font-size: 40px;
  color: #fff;
  margin: 30px;
}
.wishlistIconKV:hover {
  color: #f7567c;
}
/* ===================================== */

*,
*::before,
*::after {
  box-sizing: border-box;
}

.sideFilterColumn {
  width: 20%;
}
/* ==================================== */
/* PAINTING CARD */
/* ===================================== */

.imagePaintingTag {
  width: 460px;
  height: 315px;
  display: block;
}

.overlaya {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.3)
  );
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.5s ease;
}

.mainDivContainerPaintingCard:hover .overlaya {
  width: 100%;
}

.textPaint {
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
}

/* ======================================================= */
/* ====================   CART PAGE  ===================== */
/* ======================================================= */

.imageonCartPageCard {
  width: 180px;
  min-height: 180px;
  position: relative;
  top: 24px;
  left: 10px;
}
.optionOnCartPageCard {
  position: relative;
}
#cartPageMainContainerKV {
  background-color: #f5f5f5;
  min-height: 140vh;
}
.lastRowOptionCartCard {
  border-right: 2px solid #cccccc;
  border-left: 2px solid #cccccc;

  margin-bottom: 20px;
}
.optionTextControlCartCard:hover {
  color: #0093db;
}
#ClockCircleOutlined:hover {
  color: #0093db;
}
#PlusOutlined:hover {
  color: #0093db;
}

.bcdfgh {
  color: rgba(0, 0, 0, 0.65);
}
#DeleteOutlined:hover {
  color: #0093db;
}
.saveForLaterCart {
  position: relative;
  top: 3px;
  left: 5px;
  cursor: pointer !important;
  display: inline-block;
}
.saveForLaterCart:hover {
  color: #0093db;
}
.imageContainerCartCard {
  position: relative;
  top: 24;
  bottom: 15%;
  min-height: 300px;
}
.titleCartCard {
  font-weight: 400;
  font-size: 25px;
  left: -2.6%;
  max-width: 83%;
  font-family: "Poppins", sans-serif;
}
.columnSecondCartCard {
  position: relative;
  top: 24px;
  left: 15%;
}
.descCartCard {
  color: #a8a8a8;
  font-size: 10px;
  left: -2.45%;
  max-width: 80%;
}
.pagesTitleCartCard {
  font-size: 18px;

  display: inline-flex;
}
.countPagesCartCard {
  color: #a8a8a8;
  font-size: 17px;
  margin-left: 10px;
}
.oldPriceCartCard {
  display: inline-block;
  position: relative;
  top: 30%;
}
.amountOldCartCard {
  text-decoration: line-through;
}
.newpriceCartCard {
  margin-left: 20px;
  position: relative;
  top: 50%;
}
.freeTextCartCard {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.fa-rupee-sign {
  font-size: 25px;
}
.priceTextCartCard {
  font-size: 30px;
}
.startRatingCartCard {
  position: relative;
  top: 20%;
}
.mainDivCartCardKV {
  width: 90%;
  margin: 50px;
  background-color: #ffff;
  border-radius: 18px;
  background: #ffffff;
}
#ratingCountCartCardKV {
  position: relative;
  top: 3px;
  left: 60%;
}
.orderSummaryCartPageKV {
  position: relative;
  max-height: 800px;
  top: 75px;
  width: 100%;
  right: 24px;
  border-radius: 18px;
  background: #ffffff;
}
.titleOrderSummaryCart {
  color: rgba(0, 0, 0, 0.65);
  padding-top: 20px;
}
#totalCountPriceCrt {
  font-size: 14px;
}
.codpayCartCard {
  border-radius: 20px;
  position: relative;
  left: 28%;
}

/* MEDIA QUERY */

@media only screen and (max-width: 500px) {
  .cartCardValueKV {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imageContainerCartCard {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imageonCartPageCard {
    position: relative;
    left: 13em;
  }
  .orderSummaryCartPageKV {
    width: 300px;
    left: 20px;
  }
  .mainDivCartCardKV {
    position: relative;
    left: -10px !important;
    min-height: 500px;
    min-width: 500px;
  }
  .startRatingCartCard {
    left: -79px;
    padding-left: 10px;
    margin-bottom: 80px;
  }
  .orderSummeryComponentKV {
    position: relative;
    left: 40px;
    margin-bottom: 180px;
  }
}

@media (max-width: 700px) {
  #cartPageMainContainerKV {
    min-height: 140vh;
  }
  .imageonCartPageCard {
    position: relative;
    left: 13em;
  }
  .startRatingCartCard {
    left: -70px;
    padding-left: 50px;
    margin-bottom: 80px;
  }
  .mainDivCartCardKV {
    position: relative;
    left: 200px !important;
    min-height: 500px;
  }
  .optionOnCartPageCard {
    position: relative;
  }
  .orderSummaryCartPageKV {
    position: relative;
    max-height: 800px;
    top: 75px;
    width: 100px !important ;
    left: -20px !important;
    border-radius: 18px;

    box-shadow: inset 8px 8px 23px #cfcfcf, inset -8px -8px 23px #ffffff;
  }
}

@media only screen and (max-width: 900px) {
  .cartCardValueKV {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imageContainerCartCard {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imageonCartPageCard {
    position: relative;
    left: 13em;
  }

  .orderSummaryCartPageKV {
    position: relative;
    max-height: 800px;
    top: 75px;
    width: 100px !important ;
    left: 0px !important;
    border-radius: 18px;
    margin-bottom: 180px;
    margin-left: 33px;
    box-shadow: inset 8px 8px 23px #cfcfcf, inset -8px -8px 23px #ffffff;
  }
  .mainDivCartCardKV {
    position: relative;
    left: -10px !important;
    min-height: 500px;
    min-width: 600px;
  }
  .startRatingCartCard {
    left: -100px;
    padding-left: 110px;
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1200px) {
  .cartCardValueKV {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imageContainerCartCard {
    display: grid;
    grid-template-columns: 1fr;
  }
  .imageonCartPageCard {
    position: relative;
    left: 13em;
  }
  .orderSummaryCartPageKV {
    position: relative;
    max-height: 800px;
    top: 75px;
    width: 100% !important ;
    left: -30px !important;
    border-radius: 18px;
    margin-left: 25px;
    margin-bottom: 150px;
    box-shadow: inset 8px 8px 23px #cfcfcf, inset -8px -8px 23px #ffffff;
  }
  .mainDivCartCardKV {
    position: relative;
    left: 24%;
    min-width: 650px;
  }
  .startRatingCartCard {
    left: -100px;
    padding-left: 110px;
    margin-bottom: 80px;
  }
}

/* ====================================================== */
/* =================    HOME PAGE   ===================== */
/* ====================================================== */
.imageCarouselHome {
  height: 100vh;

  -webkit-mask-image: linear-gradient(to top, transparent 4%, black 40%);
  mask-image: linear-gradient(to top, transparent 4%, black 40%);
}
.homeMainContainer {
  background-color: #e3e3e3;
  min-height: 150vh;
}
.hellogkaisehai {
  position: relative;
  top: -150px;
  display: inline-block;
  margin-left: 30px;
}
.imageTagPcard {
  object-fit: cover;
}

/* ========================================= */
.abs,
h2:after,
.cards .card figcaption,
.cards .card:after,
.news .card figcaption,
.news .card:after,
.news .article figcaption {
  position: absolute;
}
.rel,
h2,
h2 strong,
.cards .card,
.news .card,
.news .article {
  position: relative;
}
.fix {
  position: fixed;
}
.dfix {
  display: inline;
}
.dib {
  display: inline-block;
}
.db {
  display: block;
}
.dn {
  display: none;
}
.df,
.cards,
.news {
  display: flex;
}
.dif {
  display: inline-flex;
}
.dg {
  display: grid;
}
.dig {
  display: inline-grid;
}
.vm,
h2,
h2 strong,
h2 span {
  vertical-align: middle;
}

.wrapper {
  padding: 15px;
}
h2 {
  padding: 10px;
  padding-left: 25px;
  color: #ccc;
  margin: 0;
}
h2 strong {
  z-index: 2;
  background: #24282f;
  padding: 4px 8px;
}
h2 span {
  font-size: 0.7em;
  color: #aaa;
  margin-left: 10px;
}
h2:after {
  content: "";
  z-index: 1;
  bottom: 50%;
  margin-bottom: -2px;
  height: 2px;
  left: 0;
  right: 0;
  background: #373d47;
}
.cards,
.news {
  flex-flow: row wrap;
}
.cards .card,
.news .card {
  margin: 20px;
  width: 290px;
  height: 320px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  transform-origin: center top;
  transform-style: preserve-3d;
  transform: translateZ(0);
  transition: 0.3s;
}
.cards .card img,
.news .card img {
  width: 100%;
  min-height: 100%;
}
.cards .card figcaption,
.news .card figcaption {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  background: none;
  color: #fff;
  transform: translateY(100%);
  transition: 0.3s;
}
.cards .card:after,
.news .card:after {
  content: "";
  z-index: 10;
  width: 200%;
  height: 100%;
  top: -90%;
  left: -20px;
  opacity: 0.1;
  transform: rotate(45deg);
  background: linear-gradient(
    to top,
    transparent,
    #fff 15%,
    rgba(255, 255, 255, 0.5)
  );
  transition: 0.3s;
}
.cards .card:hover,
.news .card:hover,
.cards .card:focus,
.news .card:focus,
.cards .card:active,
.news .card:active {
  box-shadow: 0 8px 16px 3px rgba(0, 0, 0, 0.6);
  transform: translateY(-3px) scale(1.05) rotateX(15deg);
}
.cards .card:hover figcaption,
.news .card:hover figcaption,
.cards .card:focus figcaption,
.news .card:focus figcaption,
.cards .card:active figcaption,
.news .card:active figcaption {
  transform: none;
}
.cards .card:hover:after,
.news .card:hover:after,
.cards .card:focus:after,
.news .card:focus:after,
.cards .card:active:after,
.news .card:active:after {
  transform: rotate(25deg);
  top: -40%;
  opacity: 0.15;
}
.news .article {
  overflow: hidden;
  width: 350px;
  height: 235px;
  margin: 20px;
}
.news .article img {
  width: 100%;
  min-height: 100%;
  transition: 0.2s;
}
.news .article figcaption {
  font-size: 14px;
  text-shadow: 0 1px 0 rgba(51, 51, 51, 0.3);
  color: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 40px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  background: rgba(6, 18, 53, 0.6);
  opacity: 0;
  transform: scale(1.15);
  transition: 0.2s;
}
.news .article figcaption h3 {
  color: #3792e3;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: bold;
}
.news .article:hover img,
.news .article:focus img,
.news .article:active img {
  -webkit-filter: blur(3px);
          filter: blur(3px);
  transform: scale(0.97);
}
.news .article:hover figcaption,
.news .article:focus figcaption,
.news .article:active figcaption {
  opacity: 1;
  transform: none;
}

/* ====== */
/* MEDIA QUERY */

@media (max-width: 900px) {
  .imageCarouselHome {
    height: 70vh;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-mask-image: linear-gradient(to top, transparent 4%, black 40%);
    mask-image: linear-gradient(to top, transparent 4%, black 40%);
  }
  .homeMainContainer {
    min-height: 150vh;
  }
  .hellogkaisehai {
    position: relative;
    top: -160px;
  }
  .imageTagPcard {
    object-fit: cover;
  }
  .cards .card,
  .news .card {
    margin-left: -20px;
    width: 225px;
    height: 250px;
  }
}

@media screen and (max-width: 700px) {
  .imageCarouselHome {
    height: 50vh;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-mask-image: linear-gradient(to top, transparent 4%, black 40%);
    mask-image: linear-gradient(to top, transparent 4%, black 40%);
  }
  .homeMainContainer {
    min-height: 150vh;
  }
  .hellogkaisehai {
    position: relative;
    top: -130px;
  }
  .imageTagPcard {
    object-fit: cover;
  }
  .cards .card,
  .news .card {
    margin-left: -20px;
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 400px) {
  .imageCarouselHome {
    height: 50vh;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-mask-image: linear-gradient(to top, transparent 4%, black 40%);
    mask-image: linear-gradient(to top, transparent 4%, black 40%);
  }
  .homeMainContainer {
    min-height: 150vh;
  }
  .hellogkaisehai {
    position: relative;
    top: -100px;
    display: inline-block;
    margin-left: 10px;
  }
  .imageTagPcard {
    object-fit: cover;
  }
  .cards .card,
  .news .card {
    margin: 0;

    width: 100px;
    height: 120px;
  }
}

@media (min-width: 901px) and (max-width: 1100px) {
  .imageCarouselHome {
    height: 70vh;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-mask-image: linear-gradient(to top, transparent 4%, black 40%);
    mask-image: linear-gradient(to top, transparent 4%, black 40%);
  }
  .homeMainContainer {
    min-height: 150vh;
  }
  .hellogkaisehai {
    position: relative;
    top: -160px;
  }
  .imageTagPcard {
    object-fit: cover;
  }
  .cards .card,
  .news .card {
    margin-left: -20px;
    width: 300px;
    height: 250px;
  }
}
@media screen and (max-width: 320px) {
  .hellogkaisehai {
    position: relative;
    top: -100px;
    display: inline-block;
    margin-left: 10px;
  }
  .imageTagPcard {
    object-fit: cover;
  }
  .homeMainContainer {
    min-height: 150vh;
  }
  .cards .card,
  .news .card {
    margin: 0;
    overflow: hidden;
    width: 70px;
    height: 70px;
  }
}

/* Banner Home Card */
.banner2HomeCard {
  height: 40vh;
  border-radius: 10px;
}
.divBannerHomeCard {
  height: 40vh;
  position: relative;
  border-radius: 37px;
}
.divBannerHomeCard:hover {
  border-radius: 37px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 5px 5px 10px #999999, -5px -5px 10px #ffffff;
}

/* ==================== */
/* CardProductHome */
.cardProductHomeContainer {
  margin-top: -60px;
}
.mainDivCardProductHome {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: inset 17px 17px 37px #e3e3e3, inset -17px -17px 37px #ffffff;
}
.mainDivCardProductHome:hover {
  border-radius: 24px;
  background: #ebebeb;
  box-shadow: inset 17px 17px 13px #d6d6d6, inset -17px -17px 13px #ffffff;
}
.cardDivCPHkv {
  margin: 36px;
  width: 18.5rem;
  max-height: 20rem;
  display: inline-block;
}

.content-wrapperkv {
  margin: 0 auto;
  width: 330px;

  padding: 0.5rem;
}
.h2EcomCardHeading {
  text-decoration: none !important;
  text-transform: none;
  color: #5e708e;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.carouselImageBannertwoHome {
  height: 60vh;
  margin-top: 25px;
}

.imageCarouselHomeBannerTwo {
  height: 50vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-mask-image: linear-gradient(to top, transparent 0.1%, black 5%);
  mask-image: linear-gradient(to top, transparent 0.1%, black 5%);
  margin-top: 25px;
}
@media screen and (max-width: 320px) {
  .content-wrapperkv {
    margin: 0 auto;
    width: 300px;
    position: relative;
    left: -15px;

    padding: 0.5rem;
  }
  .imageCarouselHomeBannerTwo {
    height: 40vh;
  }
}
@media (max-width: 900px) {
  .mainDivCardProductHome {
    grid-template-columns: 1fr;
  }
  .h2EcomCardHeading {
    font-size: 15px;
  }
}

@media (min-width: 535px) and (max-width: 720px) {
  .mainDivCardProductHome {
    grid-template-columns: 1fr;
  }
  .imageCarouselHomeBannerTwo {
    height: 40vh;
  }
  .content-wrapperkv {
    margin: 0;
    width: 400px;
    position: relative;
    left: 35px;
    padding: 0.5rem;
  }
  .h2EcomCardHeading {
    font-size: 35px;
  }
  .news-card {
    border: 0px solid aqua;
    margin: 0.5rem;
    position: relative;
    height: 12rem;
    overflow: hidden;
    border-radius: 0.5rem;
    flex: 1 1;
    width: 400px;
  }
}

@media (min-width: 720px) and (max-width: 900px) {
  .mainDivCardProductHome {
    grid-template-columns: 1fr 1fr;
  }
  .h2EcomCardHeading {
    font-size: 35px;
  }
  .imageCarouselHomeBannerTwo {
    height: 40vh;
  }
}

@media (min-width: 901px) and (max-width: 1100px) {
  .content-wrapperkv {
    margin: 0 auto;
    width: 320px;
    position: relative;
    left: -15px;

    padding: 0.5rem;
  }
  .imageCarouselHomeBannerTwo {
    height: 40vh;
  }
}

/* ====================== */

.news-card {
  border: 0px solid aqua;
  margin: 0.5rem;
  position: relative;
  height: 12rem;
  overflow: hidden;
  border-radius: 0.5rem;
  flex: 1 1;
  min-width: 290px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (min-width: 100px) and (max-width: 300px) {
  .content-wrapperkv {
    margin: 0;
    width: 260px;
    position: relative;
    left: -5px;

    padding: 0.5rem;
  }
  .imageCarouselHomeBannerTwo {
    height: 40vh;
  }

  .news-card {
    border: 0px solid aqua;
    margin: 0.5rem;
    position: relative;
    height: 12rem;
    overflow: hidden;
    border-radius: 0.5rem;
    flex: 1 1;
    min-width: 100px;
  }
  .h2EcomCardHeading {
    font-size: 15px;
  }
}

@media (min-width: 900px) {
  .news-card {
    height: 20rem;
  }
}

.news-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 80%);
  z-index: 0;
}

.news-card__card-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /*     background: rgba(255,0,0,.5); */
}

.news-card__image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: transform 3s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: -1;
}

.news-card__text-wrapper {
  position: absolute;
  bottom: 0rem;
  padding: 1rem;
  color: white;
  /*     background-color: rgba(0, 0, 0, 0.4); */
  transition: background-color 1.5s ease;
}

.news-card__title {
  transition: color 1s ease;
  margin-bottom: 0.5rem;
  color: #fff;
  text-transform: uppercase;
}

.news-card__post-date {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  color: #ccc;
}

.news-card__details-wrapper {
  max-height: 0;
  opacity: 0;
  transition: max-height 1.5s ease, opacity 1s ease;
}

@media (min-width: 900px) {
  .news-card:hover .news-card__details-wrapper {
    max-height: 20rem;
    opacity: 1;
  }
  .news-card:hover .news-card__text-wrapper {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .news-card:hover .news-card__title {
    color: yellow;
  }
  .news-card:hover .news-card__image {
    transform: scale(1.2);
    z-index: -1;
  }
}

.news-card__excerpt {
  font-weight: 300;
}

.news-card__read-more {
  background: black;
  color: #bbb;
  display: block;
  padding: 0.4rem 0.6rem;
  border-radius: 0.3rem;
  margin-top: 1rem;
  border: 1px solid #444;
  font-size: 0.8rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-decoration: none;
  width: 7rem;
  margin-left: auto;
  position: relative;
  z-index: 5;
}

.news-card__read-more i {
  position: relative;
  left: 0.2rem;
  color: #888;
  transition: left 0.5s ease, color 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.news-card__read-more:hover i {
  left: 0.5rem;
  color: yellow;
}

/* ======================================================= */
/* ProductCardDisplay.js  */
/* ======================================================= */

.mainDivCardProductCatDisplay {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 70px;
  gap: 70px;
  margin-bottom: 50px;

  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: inset 17px 17px 37px #e3e3e3, inset -17px -17px 37px #ffffff;
}
.mainDivCardProductCatDisplay:hover {
  border-radius: 24px;
  background: #ebebeb;
  box-shadow: inset 17px 17px 13px #d6d6d6, inset -17px -17px 13px #ffffff;
}
.imageContProdCatDisplay:hover {
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 17px 17px 100px #666666, -17px -17px 100px #ffffff;
  cursor: pointer;
}

.mainDivCardProductCatDisplayPremium {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 70px;
  gap: 70px;
  margin-bottom: 50px;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (max-width: 900px) {
  .mainDivCardProductCatDisplay {
    grid-gap: 10px;
    gap: 10px;
  }
  .mainDivCardProductCatDisplayPremium {
    grid-gap: 10px;
    gap: 10px;
  }
}

/* ========================================================= */
/*  SINGLE PRODUCT  PAGE  */
/* ========================================================= */

.mainDivSingleProductgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 60px;
  margin-left: 20px;
}
.cardSingleProductdiv {
  margin-top: 30px;
  min-height: 100vh;
}
.colTwoSingleProductMain {
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding-left: 20px;
}
.catNmeProductListItem {
  font-size: 23px;
  text-transform: uppercase;
  font-weight: 600;
  color: #282c3f;
}
.productTitlePlistItem {
  color: #535665;
  font-weight: 400;
  font-size: 18px;
  opacity: 0.8;
  text-transform: capitalize;
}
.oldPricePLitem {
  text-decoration: line-through;
  font-size: 20px;
  font-weight: 300;
  color: #535665;
  opacity: 0.6;
  display: inline-block;
}
.newPricePLitem {
  font-size: 24px;
  color: #282c3f;
  display: inline-block;
  margin-right: 10px;
}
.percentOffPLitem {
  color: #0093db;
  display: inline-block;
  font-size: 20px;
  margin-left: 10px;
  font-weight: 400;
}
.inctaxPCLitem {
  font-size: 13px;
  font-weight: 400;
  color: #03a685;
}
.addTOcartButtonPLitem {
  border-radius: 4px;
  padding: 12px 15px;
  width: 40%;
  font-weight: 500;
  cursor: pointer;
  background-color: #ff905a;
  border: 1px solid #ff905a;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-top: 4%;
  margin-bottom: 3%;
  margin-right: 3%;
}
.addTOcartButtonPLitem:hover {
  color: #fff;
  background-image: linear-gradient(to bottom, #f03661, #f7567c);
}
.cartIconPLitem {
  color: #fff;
  margin-right: 30px;
  font-weight: 600;
  font-size: 30px;
}
.cartHover:hover {
  background-image: linear-gradient(to bottom, #f38249, #f79767);
}
.liveDemoIcon {
  margin-right: 20px;
  font-size: 30px;
}
.liveDemoButton {
  border-radius: 4px;
  padding: 12px 15px;
  width: 40%;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;

  background-image: linear-gradient(to right, #f38249, #ff3e6c);
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
  letter-spacing: 2px;
  margin-left: 22%;
}
.liveDemoButton:hover {
  background-image: linear-gradient(to right, #f79260, #eb5477);
  color: #fff;
}
@media (max-width: 1100px) {
  .mainDivSingleProductgrid {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 0px;
    margin-top: 5px;
  }

  .colTwoSingleProductMain {
    padding-left: 2px;
  }
  .addTOcartButtonPLitem {
    width: 43%;
    padding: 12px 10px;
    margin-left: 10px;
    font-size: 15px;
  }
  .cartIconPLitem {
    font-size: 20px;
    margin-right: 10px;
  }
  .liveDemoButton {
    width: 60%;
    font-size: 18px;
    margin-left: 20%;
  }
}

@media (min-width: 250px) and (max-width: 350px) {
  .liveDemoButton {
    width: 70%;
    font-size: 12px;
    margin-left: 14%;
  }
  .addTOcartButtonPLitem {
    width: 40%;
    padding: 8px 6px;
    margin-left: 14px;
    font-size: 10px;
  }
  .cartIconPLitem {
    font-size: 12px;
    margin-right: 10px;
  }
}
/* =============================================================== */
/* VECTOR CARD */
/* =============================================================== */

.mainDivVectorCard {
  display: flex;
  position: relative;
  width: 100%;
  -ms-layout-grid-type: default;
  margin-top: 100px;
}
.img {
  display: block;
  width: 100%;
  height: auto;
}
img {
  max-width: 100%;
}
.vectorDiv {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 1.45rem;
}
/* =========================================================== */
/* =========  VECTOR CARD ===============  */
/* =========================================================== */
.containerForVectorImage {
  position: relative;
}

.overlaya {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.3)
  );
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.5s ease;
}
.mainDivContainerProductCard {
  cursor: pointer;
}
.mainDivContainerProductCard:hover .overlaya {
  width: 100%;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.downLoadIconVector {
  margin-bottom: 20px;

  margin-left: 50%;
  font-size: 25px;
  color: #fff !important;
  font-weight: 400;
}
.downLoadIconVector:hover {
  color: #f03661 !important;
}
.previewButtonVector {
  background-image: linear-gradient(to right, #e98553, #f74c74);
  width: 130px;
}
.previewButtonVector:hover {
  background-image: linear-gradient(to right, #eb6e30, #e02954);
}

.singleImageImgTag {
  max-height: 500px;
}
.imageContainerDivAKv {
  justify-self: center;
  max-height: 500px;
}
.divContainerSingleVectorkv {
  margin-left: 25px;
  border: 10px solid black;
  max-height: 615px;
  max-width: 500px;
}
.mainDivPageSingleVector {
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.downLoadButtonSingleVector {
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  width: 85%;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  background-color: #e02954;
  border: 1px solid #e02954;
  color: #fff;
}
.downLoadButtonIconSingleVector {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 30px;
}
@media (max-width: 1100px) {
  .mainDivPageSingleVector {
    grid-template-columns: 1fr;
  }
  .downLoadButtonSingleVector {
    width: 60%;
  }
}

.vectorMadeBy {
  margin-top: 20%;
  margin-left: 10%;
}
.h5inVectorMadeBy {
  color: #000;
}
.h5inVectorMadeBy:hover {
  color: #e02954 !important;
}

/* PAINTING */
.antImagePainting {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover !important;
  object-position: center;
}
.overLayContainerKV {
  position: relative;
  grid-gap: 20px;
  gap: 20px;
}
.AvatarOnHover {
  color: #fff !important;
}
.overlayAfterEffect {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(/static/media/grey_gredient.7fb59d80.svg);
  z-index: 2;
  width: 100%;

  overflow: hidden;
  height: 0%;
  transition: all 0.5s ease;
}

.overLayContainerKV:hover .overlayAfterEffect {
  height: 50px;
  color: #fff !important;
}
.overLayContainerKV:hover .avatarPainting {
  display: none;
  transition: all 0.5s ease;
}
.overLayContainerKV:hover {
  cursor: pointer;
}

.nameStarOverlay {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
.wishlistIconPaintingKV {
  font-size: 20px;
  margin-left: -50px;
  cursor: pointer;
}
.wishlistIconPaintingKV:hover {
  color: #f03661;
  cursor: pointer;
}
.cartIconPaintingKV {
  font-size: 20px;
  margin-left: -20px;
  cursor: pointer;
}
.cartIconPaintingKV:hover {
  color: #f03661;
  cursor: pointer;
}
.AvatarOnHover {
  padding-top: 6%;
  padding-left: 3%;

  color: #f03661 !important;
}
.previewAddToCartPainting {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* VECTORS CARD */

.displayflex {
  display: flex;
  overflow-x: scroll;
  background-color: #fff;
}
.displayflex::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: #fff;
}
.sponsors {
  margin-top: 100px;
  background-color: #fff;
}

.buttonSubName {
  margin: 10px;
  border-radius: 80px;
  background: #ffffff;
  box-shadow: 10px 10px 20px #bdbdbd, -10px -10px 20px #ffffff;
  font-size: 15px;
  font-weight: 600;
  color: #5e5e5e;
  min-width: 150px;
  text-align: center;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}
.alphakislayvats {
  color: #fff;
}
.wishlistVector {
  position: relative;
  font-size: 20px;
  left: -20px;
  bottom: 14px;
}
.wishlistVector:hover {
  color: #f03661;
  cursor: pointer;
}
.mainContainerVectorPage {
  margin: 2%;
}
@media (max-width: 1200px) {
  .overlayAfterEffect {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(/static/media/Hover_shade.dfec97e0.svg);
    z-index: 2;
    width: 100%;
    height: 50px;
    overflow: hidden;

    transition: all 0.5s ease;
  }
}
/* LANDING PAGE */
.coverPhotoContainerLandingRight {
  background-image: url(/static/media/righ_side_up3-8.fb3f3fbb.png);
  height: 120vh;
  width: 100%;
  position: relative;
  left: 50%;
}
.coverPhotoContainerLandingLeft {
  background-image: url(/static/media/left_side_up_1.70d974df.svg);
  height: 100vh;
  width: 100%;
  position: absolute;
}

/* WEBINAR HOME PAGE */
#nprogress .bar {
  height: 4px !important;
}

.ql-editor {
  min-height: 300px;
}

.featuredimageweb {
  margin-top: 70px;
  width: 100%;
  height: 500px;
  object-fit: cover;
  box-shadow: 0 5px 20px 0;
}
.conttitle {
  font-family: "Libre Baskerville", serif;
  font-size: 60px;
  font-weight: 500;
}
/* ==============================================webinar============================================================ */

#idlabel {
  color: black;
}

#imgweb {
  display: flex;
  justify-content: center;
  align-items: center;
}

.labelz {
  color: black;
}

.txtaaz {
  color: black;
}

/* .chinesepart1 {} */
.photoz {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: 50px;
}

.chinesemainz {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.butuonz {
  margin-left: 30%;
}

.butcheckz {
  margin: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.asdfghz {
  height: 50px;
  width: 50px;
  margin-top: 10%;
}

#captchaz {
  height: 30px;
  width: 30px;

  margin: 20px;
}

.butedz {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  margin-left: 20%;
  margin-bottom: 30px;
  margin-right: 20%;
  background-color: rgba(95, 158, 160, 0.356);
}

.label2z {
  margin-top: 10px;
}

select {
  width: 90%;
  height: 35px;
  /* margin-top: 10px; */
  /* padding: 16px 20px; */
  border: none;
  border-radius: 4px;
  background-color: #f1f1f1;
}

textarea {
  margin-top: 20px;
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  border: none;
  box-sizing: border-box;
  border-bottom: 2px solid rgb(250, 225, 5);
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

.message2z {
  margin-top: 20px;
}

.chinesez {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10px;
  border: black solid 2px;
  border-radius: 10px;
  /* box-shadow: -10px -10px 10px black; */
}

.h1asz {
  text-align: center;
}

.butz {
  color: black;
  margin-bottom: 30px;
}

.buttonz {
  width: 200px;
  height: 50px;
  color: white;
  font-size: 25px;
  border-radius: 5px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonz:hover {
  color: black;
  background-color: white;
  font-weight: 700;
}

.txtaaz {
  flex: 1 1;
  padding: 18px 40px;
  font-size: 16px;
  color: black;
  background: #f1f1f1;
  border: none;
  font-weight: 700;
  outline: none;
  border-radius: 4px;
  width: 90%;
  height: 20px;
}

.containerz {
  margin-top: 15%;
}

#yy {
  background-color: white;
}

.h3tagz {
  /* box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.8); */
  margin-top: 70px;
  margin-right: 20%;
  margin-left: 20%;
  font-size: 20px;

  text-align: left;
  background-color: rgba(238, 255, 251, 1);
}

.display-4z {
  color: black;
  position: relative;
  margin-top: 150px;
  text-align: center;
}

.classz {
  display: block;
  justify-content: center;
  align-items: center;
}

.imgblogz {
  margin-bottom: 10%;
  position: relative;
  top: 100px;
  /* clip-path: polygon(50% 0%, 100% 0, 100% 85%, 50% 100%, 0 85%, 0% 60%, 0 0); */
  width: 80%;
  height: 50vh;
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.8);
}

@media (max-width: 950px) {
  .chinesemainz {
    display: grid;
    grid-template-columns: 1fr;
  }
  .conttitle {
    font-family: "Libre Baskerville", serif;
    font-size: 25px;
    font-weight: 500;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .imgblogz {
    margin-bottom: 20%;
    position: relative;
    top: 100px;
    /* clip-path: polygon(50% 0%, 100% 0, 100% 85%, 50% 100%, 0 85%, 0% 60%, 0 0); */
    width: 95%;
    height: 40vh;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.8);
  }
  .conttitle {
    font-family: "Libre Baskerville", serif;
    font-size: 20px;
    font-weight: 500;
  }
}

/* ==================================footer============================================================================ */
#alpha {
  display: none;
}

.but {
  margin-top: 10px;
  margin-bottom: 10px;
}

.txta {
  flex: 1 1;
  padding: 18px 40px;
  font-size: 12px;

  background: #ddd;
  border: none;
  font-weight: 700;
  outline: none;
  border-radius: 10px;
  width: 90%;
  height: 20%;

  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.rights-text {
  color: rgba(245, 245, 245, 0.808);
}

.fourth {
  margin-top: 15%;
  margin-left: 10%;
}

.margin {
  margin-top: 50px;
}

h5 .h5footer {
  color: whitesmoke;
  text-decoration: none;
  font-size: 12px;
}

label {
  color: rgba(245, 245, 245, 0.74);
}

h5 .h5footer:hover {
  color: rgba(245, 245, 245, 0.836);
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.third {
  margin-top: 15%;
  margin-left: 50px;
}

.h1footer {
  color: whitesmoke;
  font-size: 16px;
}

.h5footer {
  font-size: 12px;
  font-weight: 400;
}

.h5footer a {
  color: rgba(245, 245, 245, 0.596);
  text-decoration: none;
  font-size: 400;
}

.para {
  color: rgba(245, 245, 245, 0.596);
  font-size: 12px;
}

footer {
  background: rgb(49, 49, 49);
  padding-top: 50px;
  padding-bottom: 5%;
}

.footer-container {
  /* margin-top: 50px; */
  max-width: 100%;
  background-color: #363c4d;
  padding-left: 5%;
  padding-bottom: -5%;
  padding-right: 5%;

  /* padding: 0 20px; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.logo {
  width: 180px;
}

.social-media {
  margin: 20px 0;
}

.social-media a {
  color: #05a1cc;
  margin-right: 25px;
  font-size: 22px;
  text-decoration: none;
  transition: 0.3s linear;
}

.social-media a:hover {
  color: white;
}

.right-col {
  margin-top: 15%;
  margin-left: 50px;
  margin-right: 50px;
}

.border {
  width: 100px;
  height: 4px;
  background: #fc5c65;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.txtb {
  flex: 1 1;
  padding: 18px 40px;
  font-size: 16px;
  color: #293043;
  background: #ddd;
  border: none;
  font-weight: 700;
  outline: none;
  border-radius: 30px;
  min-width: 260px;
}

.buttonstylebc {
  margin-left: 4%;
  width: 200px;
  height: 40px;
  color: white;
  font-size: 16px;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonstylebc:hover {
  color: #fff;
  background-color: transparent;
  font-weight: 700;
}

.page-content {
  min-height: 100vh;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 
@media (max-width:960px) {
  .footer-container {
      max-width: 600px;
  }

  .right-col {
      width: 100%;
      margin-bottom: 60px;
  }

  .left-col {
      width: 100%;
      text-align: center;
  }

  .footer-container {
      max-width: 100%;
      margin: 0;
      padding: 0 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
  }
} */

@media (min-width: 600px) and (max-width: 996px) {
  .btns {
    margin: 0;
    width: 100%;
    margin-top: 20px;
  }

  .footer-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr !important;

    margin: 0;
  }

  footer {
    margin-top: 0px;
    background: black;
    padding-left: 5px;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .btns {
    margin: 0;
    width: 100%;
    margin-top: 20px;
  }

  .footer-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr !important;

    margin: 0;
  }

  footer {
    margin-top: 50px !important;
    background: black;
    padding-left: 5px;
  }
}

@media (max-width: 400px) {
  .btns {
    margin: 0;
    width: 100%;
    margin-top: 20px;
  }

  .footer-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr !important;

    margin: 0;
  }

  footer {
    margin-top: 0px !important;
    background: #363c4d;
    padding-left: 5px;
  }

  #alpha {
    display: block;
  }

  #beta {
    display: none;
  }
}

#idlabel {
  color: black;
}
#imgweb {
  display: flex;
  justify-content: center;
  align-items: center;
}
.label {
  color: black;
}
.txtaa {
  color: black;
}
/* .chinesepart1 {} */
.photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: 50px;
}
.chinesemain {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.butuon {
  margin-left: 30%;
}
.butcheck {
  margin: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.asdfgh {
  height: 50px;
  width: 50px;
  margin-top: 10%;
}
#captcha {
  height: 30px;
  width: 30px;
  margin: 20px;
}
.buted {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  margin-left: 20%;
  margin-bottom: 30px;
  margin-right: 20%;
  background-color: rgba(95, 158, 160, 0.356);
}
.label2 {
  margin-top: 10px;
}
select {
  width: 90%;
  height: 35px;
  /* margin-top: 10px; */
  /* padding: 16px 20px; */
  border: none;
  border-radius: 4px;
  background-color: #f1f1f1;
}
textarea {
  margin-top: 20px;
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  border: none;
  box-sizing: border-box;
  border-bottom: 2px solid rgb(250, 225, 5);
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}
.message2 {
  margin-top: 20px;
}
.chinese {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10px;
  border: black solid 2px !important;
  border-radius: 10px;
  /* box-shadow: -10px -10px 10px black; */
}
.h1as {
  text-align: center;
}
.but {
  color: black;
  margin-bottom: 30px;
}
.button {
  width: 200px;
  height: 50px;
  color: white;
  font-size: 25px;
  border-radius: 5px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button:hover {
  color: black;
  background-color: white;
  font-weight: 700;
}
.txtaa {
  flex: 1 1;
  padding: 18px 40px;
  font-size: 16px;
  color: black;
  background: #f1f1f1;
  border: none;
  font-weight: 700;
  outline: none;
  border-radius: 4px;
  width: 90%;
  height: 20px;
}
/* .container {
  margin-top: 15%;
} */
#yy {
  background-color: white;
}
.h3tag {
  /* box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.8); */
  margin-top: 70px;
  margin-right: 20%;
  margin-left: 20%;
  font-size: 20px;
  text-align: left;
  background-color: rgba(238, 255, 251, 1);
}
.display-4 {
  color: black;
  position: relative;
  margin-top: 150px;
  text-align: center;
}
.class {
  display: block;
  justify-content: center;
  align-items: center;
}
.imgblog {
  margin-bottom: 10%;
  position: relative;
  top: 100px;
  /* clip-path: polygon(50% 0%, 100% 0, 100% 85%, 50% 100%, 0 85%, 0% 60%, 0 0); */
  width: 80%;
  height: 50vh;
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.8);
}
@media (max-width: 950px) {
  .chinesemain {
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 300px) and (max-width: 600px) {
  .imgblog {
    margin-bottom: 20%;
    position: relative;
    top: 100px;
    /* clip-path: polygon(50% 0%, 100% 0, 100% 85%, 50% 100%, 0 85%, 0% 60%, 0 0); */
    width: 95%;
    height: 40vh;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.8);
  }
}
/* ======================== input box style==================================== */
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
/* body{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(-135deg, #c850c0, #0093dc);
} */
.wrapper {
  width: 450px;
  background: #fff;
  padding: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.wrapper .input-data {
  height: 40px;
  width: 100%;
  margin-bottom: 10%;
  position: relative;
}
.wrapper .input-data input {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid silver;
}
.input-data input:focus ~ label,
.input-data input:valid ~ label {
  transform: translateY(-20px);
  font-size: 15px;
  color: #0093dc;
}
.wrapper .input-data label {
  position: absolute;
  bottom: 10px;
  left: 0;
  color: grey;
  pointer-events: none;
  transition: all 0.3s ease;
}
.input-data .underline {
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
}
.input-data .underline:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #0093dc;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before {
  transform: scaleX(1);
}

/* ========================================================================== */
.eventimage {
  width: 500px;
  height: 200px;
}
/* ======================eventform============================================ */
.butful label {
  margin-bottom: -5%;
  padding-left: 10%;
  color: rgba(0, 0, 0, 0.728) !important;
}
.displayh4 {
  color: #1f85de;
  margin-top: 5%;
  font-size: 40px;
  font-weight: 500;
  margin-left: 5%;
}
#idlabel {
  color: black;
}
label {
  font-size: 12px;
}
#imgweb {
  display: flex;
  justify-content: left;
  align-items: left;
}
.label {
  color: black;
}
.txtaa {
  color: black;
}
.chinesepart1 {
  margin-top: 00%;
  margin-left: 5%;
}
.photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: 50px;
}
.chinesemain {
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.butuon {
  margin-left: 10%;
}
.butcheck {
  margin: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.asdfgh {
  height: 50px;
  width: 50px;
  margin-top: 10%;
}
#captcha {
  height: 30px;
  width: 30px;
  margin: 20px;
}
.buted {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  margin-left: 20%;
  margin-bottom: 30px;
  margin-right: 20%;
  background-color: rgba(95, 158, 160, 0.356);
}
.label2 {
  margin-top: 10px;
}
select {
  width: 90%;
  height: 35px;
  /* margin-top: 10px; */
  /* padding: 16px 20px; */
  border: none;
  border-radius: 4px;
  background-color: #f1f1f1;
}
textarea {
  margin-top: 20px;
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  border: none;
  box-sizing: border-box;
  border-bottom: 2px solid rgb(250, 225, 5);
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}
.message2 {
  margin-top: 20px;
}
.chinese {
  margin-top: 0%;
  margin-bottom: 10%;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 5%;
  border: none;
  border-radius: 10px;
  /* box-shadow: -10px -10px 10px black; */
}
.h1as {
  text-align: center;
}
.but {
  color: black;
  margin-bottom: 10px;
}
.button {
  width: 200px;
  height: 50px;
  color: white;
  font-size: 25px;
  border-radius: 5px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button:hover {
  color: black;
  background-color: white;
  font-weight: 700;
}
.txtaa {
  flex: 1 1;
  padding: 5%;
  margin-left: 10%;
  font-size: 16px;
  background-color: rgba(234, 237, 237, 0.541) !important;
  color: black;
  background: none;
  border: none;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  font-weight: 500;
  outline: none;
  border-radius: 0px;
  width: 70%;
  height: 20px;
}
/* ====================================index page ============================================ */
.heading {
  color: #ffffff;
  font-size: 30px;
  margin-top: 3%;
}

.hrefforc {
  text-decoration: none !important;
}

.fortop {
  transition: ease-in-out;
}

.fortop1 {
  transition: ease-in-out;
}

.fortop2 {
  transition: ease-in-out;
}

.marginforbutton {
  margin-top: 30px;
}

.scweb {
  color: white;
  text-align: center;
}

.formmaachuda {
  color: white;
  background-color: #5ac3e0;
  border: 2px solid white;
  padding-bottom: 50px;
  padding-top: 50px;
  border-radius: 20px;
}

.textareafor {
  outline: none;
  border: none;
  border-radius: 20px;
  width: 98%;
  height: 200px;
}

.textareafor:focus {
  outline: none;
}

.name {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.companyname::-webkit-input-placeholder {
  color: white;
  opacity: 0.7;
}

.companyname:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
}

.companyname::-ms-input-placeholder {
  color: white;
  opacity: 0.7;
}

.companyname::placeholder {
  color: white;
  opacity: 0.7;
}

.Geography::-webkit-input-placeholder {
  color: white;
  opacity: 0.7;
}

.Geography:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
}

.Geography::-ms-input-placeholder {
  color: white;
  opacity: 0.7;
}

.Geography::placeholder {
  color: white;
  opacity: 0.7;
}

.firstname::-webkit-input-placeholder {
  color: white;
  opacity: 0.7;
}

.firstname:-ms-input-placeholder {
  color: white;
  opacity: 0.7;
}

.firstname::-ms-input-placeholder {
  color: white;
  opacity: 0.7;
}

.firstname::placeholder {
  color: white;
  opacity: 0.7;
}

.companyname {
  color: white;
  background-color: #5ac3e0;
  outline: none;
  border: none;
  border-bottom: 2px solid white;
  margin-bottom: 25px;
  margin-top: 25px;
  width: 98%;
}

.Geography {
  color: white;
  background-color: #5ac3e0;
  outline: none;
  border: none;
  border-bottom: 2px solid white;
  margin-bottom: 25px;
  margin-top: 25px;
  width: 98%;
}

.firstname {
  color: white;
  background-color: #5ac3e0;
  outline: none;
  border: none;
  border-bottom: 2px solid white;
  margin-bottom: 25px;
  margin-top: 25px;
  width: 95%;
}

.dropdown-menu {
  width: 100%;
  text-align: left;
  background-color: white;
  color: black;
}

.dropsss {
  width: 100%;
  background-color: white;
  color: black;
  text-align: left;
  border: none;
}

.dropsss:hover {
  background-color: white;
  color: black;
}

.imagedas {
  margin-top: 0px;
}

.sign {
  width: 60px;
  height: 60px;
}

.h1aasdj {
  margin-top: 50px;
  margin-bottom: 50px;
}

.h1aasdjas {
  cursor: pointer;

  display: inline-block;
}

.expo {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.imgsource {
  margin-top: -300px;

  width: 100%;
  height: 160vh;
  margin-bottom: -400px;
}

.imgsrcimages {
  width: 100%;
  height: 100%;
}

.fa-check {
  font-style: oblique;
  color: #1f85de;
  font-size: 25px;
  font-weight: 600;
  margin-right: 10px;
}

.tick {
  display: inline-flex;
}

.pp1 {
  margin-top: 50px;
}

.cardstyle {
  margin-top: 40px;
}

.paraforwhatisfor {
  text-align: center;
}

.paraforwhatisforp {
  text-align: left;
}

.fa-users {
  margin-bottom: 40px;
  margin-left: 40%;
  width: 100px;
  height: 100px;
  color: #1f85de;
}

.cardswhatis {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.style14 {
  border: 0;
  height: 1.5px;
  background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
}

.detailsgrid {
  margin-top: 100px;
  margin-bottom: 100px;

  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.first:hover {
  border-bottom: 2px solid #1f85de;
  background-color: white;
  color: black;
  cursor: pointer;
}

.first {
  font-size: 14px;
  font-weight: 600;
  color: white;
  text-transform: capitalize;
  cursor: pointer;
  line-height: 50px;
  text-align: center;
}

.allcomp {
  cursor: pointer;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.navcomponentfor {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.contactusafter {
  width: 100%;
  height: 50px;
  background: linear-gradient(
    to right,
    rgba(31, 133, 222, 0.767),
    rgba(142, 193, 237, 0.521)
  );
}

.cardfor2column {
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.anchorforwhatis {
  text-decoration: none;
  color: #1f85de;
}

.paraforwhatis {
  font-size: 22px;
  text-align: center;
}

.h2webinarwhatis {
  text-transform: capitalize;
  text-align: center;
}

.h3webinarwhatis {
  text-align: left;
}

.firstthing {
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 50px;
  color: #1f85de;
  text-align: center;
}

.textaling {
  text-align: center;
  font-size: 60px;
  font-weight: 900;
}

.href {
  text-decoration: none !important;
}

.baby {
  margin-right: 50px;
}

.baba {
  margin-left: 50px;
}

.wrappered {
  position: relative;

  perspective: 40em;
  display: grid;
  transform-style: preserve-3d;
}

.cardss {
  grid-area: 1 / 1;
  height: 200px;
  width: 400px;
  transform: translateX(10px) rotateY(25deg) rotateX(10deg);
  background: rgba(249, 198, 26, 0.88);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  color: #000;
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 900;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  box-shadow: 0 10px 30px -3px rgba(0, 0, 0, 0.1);
}

.cardss .enclosed {
  background: #000;
  line-height: 1;
  color: rgba(249, 198, 26, 1);
  padding: 0 5px;
  display: inline-block;
  transform: translate(-1px, 1px) scale(0.75);
  transform-origin: right center;
}

.wrappered:before {
  --bw: 9px;
  grid-area: 1 / 1;
  content: "";
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  height: 100%;
  width: 100%;
  margin-top: calc(-1 * var(--bw));
  margin-left: calc(-1 * var(--bw));
  background: transparent;
  transform: translateX(-60px) rotateY(-30deg) rotateX(15deg) scale(1.03);
  pointer-events: none;
  border: var(--bw) solid #000;
  box-sizing: content-box;
}

.wrappered:hover > div,
.wrappered:hover:before {
  transform: none;
}

.wrappered > div,
.wrappered:before {
  will-change: transform;
  transition: 0.3s transform cubic-bezier(0.25, 0.46, 0.45, 1);
}

.blogscontain {
  display: grid;
  place-items: center;
  background: linear-gradient(to bottom right, #3c4bbd 15%, transparent),
    radial-gradient(
        circle at 100% 0%,
        rgba(255, 255, 255, 0.2) 10%,
        transparent 20%
      )
      center center / 15px 15px,
    linear-gradient(to bottom right, #3c4bbd, #57add8);
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* .card1 {
  margin-left: 150px;
  margin-top: 100px;
  background-color: blue;
  height: 500px;
  width: 400px;
}

.card2 {
  margin-left: 150px;
  margin-top: 100px;
  background-color: blue;
  height: 500px;
  width: 400px;
} */

.blogscontain {
  background-image: url(/static/media/right.34b96d77.png);
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.spandesk {
  font-size: 60px;
}

.leftcar {
  text-align: center;
  margin-top: 50vh;
  color: white;
}

.fa-pen-square {
  margin-right: 5px;
  color: #1f85de;
  font-size: 25px;
}

.fa-envelope {
  margin-right: 5px;
  color: #1f85de;
  font-size: 25px;
}

.fa-id-badge {
  margin-right: 5px;
  color: #1f85de;
  font-size: 25px;
}

.butana {
  margin-left: 100px !important;
  width: 200px;
  height: 50px;
  color: white;
  font-size: 25px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgba(0, 153, 213, 0.356),
    rgba(53, 229, 176, 0.336)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
}

.butana:hover {
  color: black;
  background: linear-gradient(
    to right,
    rgba(0, 153, 213, 0.356),
    rgba(53, 229, 176, 0.336)
  );

  font-weight: 700;
}

.butted {
  margin-top: 70px;
}

.txtab {
  flex: 1 1;
  padding-bottom: 10px;
  font-size: 16px;

  border: none;
  font-weight: 500;
  outline: none;

  width: 70%;
  height: 20%;
  border-bottom: rgba(128, 128, 128, 0.815) 2px solid;

  color: black;
}

.white {
  color: black;

  box-shadow: 10px 10px 30px gray;
  border-radius: 30px;
  width: 500px;
  height: 450px;
  background-color: white;

  margin: 10px;
  margin-top: 240px;
  margin-left: 50px;
  padding-left: 50px;
}

.blue {
  background-image: url(/static/media/m3.5171e4e4.svg);

  background-size: cover;
  height: 105vh;
  width: 100%;
  display: grid;
  margin-bottom: 100px;
  grid-template-columns: 1fr 1fr;
}

.marginleft {
  padding-left: 50px;
  padding-right: 50px;
}

.recentwebinar {
  padding-left: 40%;
  padding-right: 40%;
}

.classh2classas a {
  letter-spacing: 3px;
  text-align: center;
  color: white;
  background-color: cornflowerblue;
  font-size: 30px;
}

.h1classh1as {
  font-size: 60px;
  letter-spacing: 15px;
  text-align: center;

  font-weight: 700;
  color: #6a686a;
}

.recentwebinar {
  background-image: url(/static/media/banner.0eb1110f.png) !important;

  background-size: cover;
  min-height: 70vh;
  width: 100%;
}

.backgroundimgas {
  background: linear-gradient(to right bottom, #cccccc41, #cccccca9),
    url(/static/media/v3webinargirl.7abb8298.jpeg);

  background-size: cover;
  width: 100%;
  min-height: 90vh;
}

.title {
  position: relative;
  padding-top: 20%;
  padding-left: 4%;
}

.subtitle {
  position: relative;

  padding-left: 4%;
}

.button {
  width: 200px;
  height: 50px;
  color: white;
  font-size: 25px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgba(0, 153, 213, 0.356),
    rgba(53, 229, 176, 0.336)
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  color: black;
  background-color: white;
  font-weight: 700;
}

.web {
  font-size: 25px;
}

.blogs {
  position: relative;

  transform: translateY(-50%);
}

.blog {
  overflow: hidden;
  position: relative;
  height: 300px;
  cursor: pointer;
}

.blog img {
  width: 100%;
  height: 100%;
}

.blog-description {
  position: absolute;
  bottom: -40px;
  background: #333333ca;
  width: 100%;
  padding: 40px;
  transition: 0.3s linear;
}

.blog:hover .blog-description {
  bottom: 0;
}

.categorie {
  display: inline-block;
  color: #e77f67;
  font-size: 18px;
  position: relative;
  padding: 0 22px;
}

.categorie::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 2px;
  background: #e77f67;
  left: 0;
  top: 13px;
}

.categorie::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 2px;
  background: #e77f67;
  right: 0;
  top: 13px;
}

.title {
  color: #fff;
  font-weight: 500;
  margin: 5px 0;
}

.date {
  font-style: italic;
  color: #e77f67;
  margin-bottom: 20px;
}

.btnsa {
  display: inline-block;
  color: #e77f67;
  text-decoration: none;
  border: 1px solid #e77f67;
  padding: 6px 20px;
  opacity: 0;
  transition: 0.3s linear;
}

.blog:hover .btnsa {
  opacity: 1;
}

.btnsa:hover {
  color: #fff;
  background: #e77f67;
}

.owl-stage {
  display: flex !important;
}

@media (min-width: 996px) and (max-width: 1026px) {
  .heading {
    font-size: 25px;
    margin-top: 5%;
  }

  .imgsource {
    margin-top: -800px;

    width: 100%;
    height: 160vh;
    margin-bottom: -800px;
  }

  .leftcar {
    color: black !important;
    text-align: center;
    margin-top: 20vh;
    color: white;
  }

  .white {
    color: black;
    box-shadow: 10px 10px 30px gray;
    border-radius: 30px;
    width: 500px;
    height: 450px;
    background-color: white;
    margin: 10px;
    margin-top: 0px;
    margin-left: 130px;
    padding-left: 50px;
  }

  .blue {
    background-image: url(/static/media/m3.5171e4e4.svg);

    background-size: cover;
    height: 100vh;
    width: 100%;
    margin-top: -200px;
    display: grid;
    margin-bottom: 100px;
    grid-template-columns: 1fr;
  }

  .href {
    margin-bottom: 50%;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr;
  }

  .marginleft {
    padding-left: 0;
    padding-right: 0;
  }

  .backgroundimgas {
    background: linear-gradient(to right bottom, #cccccc41, #cccccca9),
      url(/static/media/v3webinargirl.7abb8298.jpeg);

    background-size: cover;
    width: 100%;

    min-height: 50vh;
    margin-bottom: 0%;
  }

  .recentwebinar {
    background-image: url(/static/media/banner.0eb1110f.png);

    background-size: cover;
    min-height: 50vh;
    width: 100%;
    margin-bottom: 30%;
  }

  .recentwebinar {
    padding-left: 30%;
    padding-right: 30%;
  }

  .h1classh1as {
    font-size: 40px;
    letter-spacing: 15px;
    text-align: center;

    font-weight: 900;
    color: #6a686a;
  }
}

@media (min-width: 700px) and (max-width: 996px) {
  .heading {
    font-size: 25px;
    margin-top: 5%;
  }

  .leftcar {
    color: black !important;
    text-align: center;
    margin-top: 20vh;
    color: white;
    font-size: 20px !important;
  }

  .white {
    color: black;
    box-shadow: 10px 10px 30px gray;
    border-radius: 30px;
    width: 350px;
    height: 300px;
    background-color: white;
    margin: 10px;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 50px;
  }

  .blue {
    background-size: cover;
    height: 100vh;
    width: 100%;
    margin-top: -100px;
    display: grid;
    margin-bottom: 100px;
    grid-template-columns: 1fr;
  }

  .imgsource {
    margin-top: -490px;

    width: 100%;
    height: 160vh;
    margin-bottom: -600px;
  }

  .href {
    margin-bottom: 50%;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr;
  }

  .marginleft {
    padding-left: 0;
    padding-right: 0;
  }

  .backgroundimgas {
    background-size: cover;
    width: 100%;

    min-height: 50vh;
    margin-bottom: 0%;
  }

  .recentwebinar {
    background-size: cover;
    min-height: 50vh;
    width: 100%;
    margin-bottom: 30%;
  }

  .recentwebinar {
    padding-left: 30%;
    padding-right: 30%;
  }

  .h1classh1as {
    font-size: 40px;
    letter-spacing: 15px;
    text-align: center;

    font-weight: 900;
    color: #6a686a;
  }
}

/* 
@media (max-width: 400px) {
  .h1classh1as {
      font-size: 40px;
      letter-spacing: 10px;
      text-align: center;

      font-weight: 900;
      color: #6a686a;
  }
} */
@media (min-width: 600px) and (max-width: 700px) {
  .heading {
    font-size: 18px;
    margin-top: 5%;
  }

  .leftcar {
    color: black !important;
    text-align: center;
    margin-top: 20vh;
    color: white;
    font-size: 20px !important;
  }

  .white {
    color: black;
    box-shadow: 10px 10px 30px gray;
    border-radius: 30px;
    width: 350px;
    height: 300px;
    background-color: white;
    margin: 10px;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 50px;
  }

  .blue {
    background-size: cover;
    height: 100vh;
    width: 100%;
    margin-top: -100px;
    display: grid;
    margin-bottom: 100px;
    grid-template-columns: 1fr;
  }

  .imgsource {
    margin-top: -300px;

    width: 100%;
    height: 160vh;
    margin-bottom: -400px;
  }

  .paraforwhatisfor {
    text-align: center;
    font-size: 16px;
  }

  .paraforwhatis {
    font-size: 16px;
  }

  .paraforwhatisforp {
    text-align: left;
    font-size: 16px;
  }

  .h2webinarwhatis {
    text-align: center;
    font-size: 25px;
  }

  .h3webinarwhatis {
    font-size: 20px;
    text-align: left;
  }

  .firstthing {
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 50px;
    color: #1f85de;
    text-align: center;
  }

  .allcomp {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
  }

  .first {
    font-size: 10px;
    color: white;
    text-transform: capitalize;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
  }

  .first:hover {
    border-bottom: 2px solid #1f85de;
    background-color: white;
    color: black;
  }

  .navcomponentfor {
    display: grid;
    grid-template-columns: 1fr;
  }

  .formmaachuda {
    color: white;
    background-color: #5ac3e0;
    border: 2px solid white;
    padding-bottom: 50px;
    padding-top: 50px;
    border-radius: 20px;
    margin-bottom: 70px;
  }

  .cardfor2column {
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .cardswhatis {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .detailsgrid {
    margin-top: 100px;
    margin-bottom: 100px;

    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
  }

  .expo {
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .contactusafter {
    margin-top: -50%;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      to right,
      rgba(31, 133, 222, 0.767),
      rgba(142, 193, 237, 0.521)
    );
  }

  .butted {
    margin-top: 45px;
  }

  .butana {
    margin-left: 70px !important;
  }

  .txtab {
    flex: 1 1;
    padding-bottom: 0px;
    font-size: 16px;

    border: none;
    font-weight: 500;
    outline: none;

    width: 70%;
    height: 20%;
    border-bottom: rgba(128, 128, 128, 0.815) 2px solid;

    color: black;
  }

  .textaling {
    text-align: center;
    font-size: 40px;
    font-weight: 900;
  }

  .cardss {
    height: 180px;
    width: 270px;
  }

  .baby {
    margin-left: 30px;
  }

  .baba {
    margin-left: 30px;
  }

  .leftcar {
    color: black !important;
    text-align: center;
    margin-top: 20vh;
    color: white;
    font-size: 20px !important;
  }

  .white {
    color: black;
    box-shadow: 10px 10px 30px gray;
    border-radius: 30px;
    width: 350px;
    height: 300px;
    background-color: white;
    margin: 10px;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 50px;
  }

  .blue {
    background-size: cover;
    height: 100vh;
    width: 100%;
    margin-top: -100px;
    display: grid;
    margin-bottom: 100px;
    grid-template-columns: 1fr;
  }

  .href {
    margin-bottom: 50%;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr;
  }

  .marginleft {
    padding-left: 0;
    padding-right: 0;
  }

  .backgroundimgas {
    background: linear-gradient(to right bottom, #cccccc41, #cccccca9),
      url(/static/media/v3webinargirl.7abb8298.jpeg);

    background-size: cover;
    width: 100%;
    min-height: 40vh;
    margin-bottom: 50%;
  }

  .blog {
    overflow: hidden;
    position: relative;
    height: 200px;
    width: 200px;
    cursor: pointer;
  }

  .title {
    position: relative;
    padding-top: 20%;
    padding-left: 4%;
    font-size: 16px;
  }

  .subtitle {
    position: relative;
    font-size: 14px;
    padding-left: 4%;
  }

  .button {
    margin-left: 4%;
    width: 100px !important;
    height: 40px !important;
    color: white;
    font-size: 10px !important;
    border-radius: 5px;
    background-color: black;
  }

  .button:hover {
    color: black;
    background-color: white;
    font-weight: 700;
  }

  .recentwebinar {
    background-size: cover;
    min-height: 30vh;
    width: 100%;

    margin-bottom: 50%;
  }

  .recentwebinar {
    padding-left: 30%;
    padding-right: 30%;
  }

  .h1classh1as {
    font-size: 30px;
    letter-spacing: 15px;
    text-align: center;

    font-weight: 900;
    color: #6a686a;
  }

  .classh2classas a {
    letter-spacing: 1px;
    text-align: center;
    color: white;
    background-color: cornflowerblue;
    font-size: 16px;
  }
}

@media (min-width: 430px) and (max-width: 600px) {
  .heading {
    font-size: 18px;
    margin-top: 5%;
  }

  .imgsource {
    margin-top: -400px;

    width: 100%;
    height: 160vh;
    margin-bottom: -500px;
  }

  .paraforwhatisfor {
    text-align: center;
    font-size: 16px;
  }

  .paraforwhatis {
    font-size: 16px;
  }

  .paraforwhatisforp {
    text-align: left;
    font-size: 16px;
  }

  .h2webinarwhatis {
    text-align: center;
    font-size: 25px;
  }

  .h3webinarwhatis {
    font-size: 20px;
    text-align: left;
  }

  .firstthing {
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 50px;
    color: #1f85de;
    text-align: center;
  }

  .allcomp {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
  }

  .first {
    font-size: 10px;
    color: white;
    text-transform: capitalize;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
  }

  .first:hover {
    border-bottom: 2px solid #1f85de;
    background-color: white;
    color: black;
  }

  .navcomponentfor {
    display: grid;
    grid-template-columns: 1fr;
  }

  .formmaachuda {
    color: white;
    background-color: #5ac3e0;
    border: 2px solid white;
    padding-bottom: 50px;
    padding-top: 50px;
    border-radius: 20px;
    margin-bottom: 70px;
  }

  .cardfor2column {
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .cardswhatis {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .detailsgrid {
    margin-top: 100px;
    margin-bottom: 100px;

    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
  }

  .expo {
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .contactusafter {
    margin-top: -50%;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      to right,
      rgba(31, 133, 222, 0.767),
      rgba(142, 193, 237, 0.521)
    );
  }

  .butted {
    margin-top: 45px;
  }

  .butana {
    margin-left: 70px !important;
  }

  .txtab {
    flex: 1 1;
    padding-bottom: 0px;
    font-size: 16px;

    border: none;
    font-weight: 500;
    outline: none;

    width: 70%;
    height: 20%;
    border-bottom: rgba(128, 128, 128, 0.815) 2px solid;

    color: black;
  }

  .textaling {
    text-align: center;
    font-size: 40px;
    font-weight: 900;
  }

  .leftcar {
    color: black !important;
    text-align: center;
    margin-top: 20vh;
    color: white;
    font-size: 20px;
  }

  .cardss {
    height: 180px;
    width: 270px;
  }

  .baby {
    margin-left: 30px;
  }

  .baba {
    margin-left: 30px;
  }

  .white {
    color: black;
    box-shadow: 10px 10px 30px gray;
    border-radius: 30px;
    width: 350px;
    height: 300px;
    background-color: white;
    margin: 10px;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 50px;
  }

  .blue {
    background-size: cover;
    height: 100vh;
    width: 100%;
    margin-top: -100px;
    display: grid;
    margin-bottom: 100px;
    grid-template-columns: 1fr;
  }

  .href {
    margin-bottom: 50%;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr;
  }

  .marginleft {
    padding-left: 0;
    padding-right: 0;
  }

  .backgroundimgas {
    background-size: cover;
    width: 100%;
    min-height: 40vh;
    margin-bottom: 50%;
  }

  .blog {
    overflow: hidden;
    position: relative;
    height: 200px;
    width: 200px;
    cursor: pointer;
  }

  .title {
    position: relative;
    padding-top: 20%;
    padding-left: 4%;
    font-size: 16px;
  }

  .subtitle {
    position: relative;
    font-size: 14px;
    padding-left: 4%;
  }

  .button {
    margin-left: 4%;
    width: 100px !important;
    height: 40px !important;
    color: white;
    font-size: 10px !important;
    border-radius: 5px;
    background-color: black;
  }

  .button:hover {
    color: black;
    background-color: white;
    font-weight: 700;
  }

  .recentwebinar {
    background-size: cover;
    min-height: 30vh;
    width: 100%;

    margin-bottom: 50%;
  }

  .recentwebinar {
    padding-left: 30%;
    padding-right: 30%;
  }

  .h1classh1as {
    font-size: 30px;
    letter-spacing: 15px;
    text-align: center;

    font-weight: 900;
    color: #6a686a;
  }

  .classh2classas a {
    letter-spacing: 1px;
    text-align: center;
    color: white;
    background-color: cornflowerblue;
    font-size: 16px;
  }
}

@media (min-width: 400px) and (max-width: 430px) {
  .heading {
    font-size: 18px;
    margin-top: 5%;
  }

  .imgsource {
    margin-top: -500px;

    width: 100%;
    height: 160vh;
    margin-bottom: -600px;
  }

  .paraforwhatisfor {
    text-align: center;
    font-size: 16px;
  }

  .paraforwhatis {
    font-size: 16px;
  }

  .paraforwhatisforp {
    text-align: left;
    font-size: 16px;
  }

  .h2webinarwhatis {
    text-align: center;
    font-size: 25px;
  }

  .h3webinarwhatis {
    font-size: 20px;
    text-align: left;
  }

  .firstthing {
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 50px;
    color: #1f85de;
    text-align: center;
  }

  .allcomp {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
  }

  .first {
    font-size: 10px;
    color: white;
    text-transform: capitalize;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
  }

  .first:hover {
    border-bottom: 2px solid #1f85de;
    background-color: white;
    color: black;
  }

  .navcomponentfor {
    display: grid;
    grid-template-columns: 1fr;
  }

  .formmaachuda {
    color: white;
    background-color: #5ac3e0;
    border: 2px solid white;
    padding-bottom: 50px;
    padding-top: 50px;
    border-radius: 20px;
    margin-bottom: 70px;
  }

  .cardfor2column {
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .cardswhatis {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .detailsgrid {
    margin-top: 100px;
    margin-bottom: 100px;

    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
  }

  .expo {
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .contactusafter {
    margin-top: -205px;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      to right,
      rgba(31, 133, 222, 0.767),
      rgba(142, 193, 237, 0.521)
    );
  }

  .butted {
    margin-top: 45px;
  }

  .butana {
    margin-left: 70px !important;
  }

  .txtab {
    flex: 1 1;
    padding-bottom: 0px;
    font-size: 16px;

    border: none;
    font-weight: 500;
    outline: none;

    width: 70%;
    height: 20%;
    border-bottom: rgba(128, 128, 128, 0.815) 2px solid;

    color: black;
  }

  .textaling {
    text-align: center;
    font-size: 40px;
    font-weight: 900;
  }

  .leftcar {
    color: black !important;
    text-align: center;
    margin-top: 20vh;
    color: white;
    font-size: 20px;
  }

  .cardss {
    height: 180px;
    width: 270px;
  }

  .baby {
    margin-left: 30px;
  }

  .baba {
    margin-left: 30px;
  }

  .white {
    color: black;
    box-shadow: 10px 10px 30px gray;
    border-radius: 30px;
    width: 350px;
    height: 300px;
    background-color: white;
    margin: 10px;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 50px;
  }

  .blue {
    background-size: cover;
    height: 100vh;
    width: 100%;
    margin-top: -100px;
    display: grid;
    margin-bottom: 100px;
    grid-template-columns: 1fr;
  }

  .href {
    margin-bottom: 50%;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr;
  }

  .marginleft {
    padding-left: 0;
    padding-right: 0;
  }

  .backgroundimgas {
    background-size: cover;
    width: 100%;
    min-height: 40vh;
    margin-bottom: 50%;
  }

  .blog {
    overflow: hidden;
    position: relative;
    height: 200px;
    width: 200px;
    cursor: pointer;
  }

  .title {
    position: relative;
    padding-top: 20%;
    padding-left: 4%;
    font-size: 16px;
  }

  .subtitle {
    position: relative;
    font-size: 14px;
    padding-left: 4%;
  }

  .button {
    margin-left: 4%;
    width: 100px !important;
    height: 40px !important;
    color: white;
    font-size: 10px !important;
    border-radius: 5px;
    background-color: black;
  }

  .button:hover {
    color: black;
    background-color: white;
    font-weight: 700;
  }

  .recentwebinar {
    background-size: cover;
    min-height: 30vh;
    width: 100%;

    margin-bottom: 50%;
  }

  .recentwebinar {
    padding-left: 30%;
    padding-right: 30%;
  }

  .h1classh1as {
    font-size: 30px;
    letter-spacing: 15px;
    text-align: center;

    font-weight: 900;
    color: #6a686a;
  }

  .classh2classas a {
    letter-spacing: 1px;
    text-align: center;
    color: white;
    background-color: cornflowerblue;
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .imgsource {
    margin-top: -410px;

    width: 100%;
    height: 160vh;
    margin-bottom: -540px;
  }
  .cardss {
    height: 180px;
    width: 270px;
  }
  .heading {
    font-size: 18px;
    margin-top: 5%;
  }

  .paraforwhatisfor {
    text-align: center;
    font-size: 16px;
  }

  .paraforwhatis {
    font-size: 16px;
  }

  .paraforwhatisforp {
    text-align: left;
    font-size: 16px;
  }

  .h2webinarwhatis {
    text-align: center;
    font-size: 25px;
  }

  .h3webinarwhatis {
    font-size: 20px;
    text-align: left;
  }

  .firstthing {
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 50px;
    color: #1f85de;
    text-align: center;
  }

  .allcomp {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
  }

  .first {
    font-size: 10px;
    color: white;
    text-transform: capitalize;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
  }

  .first:hover {
    border-bottom: 2px solid #1f85de;
    background-color: white;
    color: black;
  }

  .navcomponentfor {
    display: grid;
    grid-template-columns: 1fr;
  }

  .formmaachuda {
    color: white;
    background-color: #5ac3e0;
    border: 2px solid white;
    padding-bottom: 50px;
    padding-top: 50px;
    border-radius: 20px;
    margin-bottom: 70px;
  }

  .expo {
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .cardfor2column {
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .cardswhatis {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .detailsgrid {
    margin-top: 100px;
    margin-bottom: 100px;

    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
  }

  .contactusafter {
    margin-top: -180px;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      to right,
      rgba(31, 133, 222, 0.767),
      rgba(142, 193, 237, 0.521)
    );
  }

  .butted {
    margin-top: 45px;
  }

  .butana {
    margin-left: 70px !important;
  }

  .txtab {
    flex: 1 1;
    padding-bottom: 0px;
    font-size: 16px;

    border: none;
    font-weight: 500;
    outline: none;

    width: 70%;
    height: 20%;
    border-bottom: rgba(128, 128, 128, 0.815) 2px solid;

    color: black;
  }

  .textaling {
    text-align: center;
    font-size: 40px;
    font-weight: 900;
  }

  .leftcar {
    color: black !important;
    text-align: center;
    margin-top: 20vh;
    color: white;
    font-size: 20px;
  }

  .card {
    height: 180px;
    width: 270px;
  }

  .baby {
    margin-left: 30px;
  }

  .baba {
    margin-left: 30px;
  }

  .white {
    color: black;
    box-shadow: 10px 10px 30px gray;
    border-radius: 30px;
    width: 350px;
    height: 300px;
    background-color: white;
    margin: 10px;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 50px;
  }

  .blue {
    background-size: cover;
    height: 100vh;
    width: 100%;
    margin-top: -100px;
    display: grid;
    margin-bottom: 100px;
    grid-template-columns: 1fr;
  }

  .href {
    margin-bottom: 50%;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr;
  }

  .marginleft {
    padding-left: 0;
    padding-right: 0;
  }

  .backgroundimgas {
    background-size: cover;
    width: 100%;
    min-height: 40vh;
    margin-bottom: 50%;
  }

  .blog {
    overflow: hidden;
    position: relative;
    height: 200px;
    width: 200px;
    cursor: pointer;
  }

  .title {
    position: relative;
    padding-top: 20%;
    padding-left: 4%;
    font-size: 16px;
  }

  .subtitle {
    position: relative;
    font-size: 14px;
    padding-left: 4%;
  }

  .button {
    margin-left: 4%;
    width: 100px !important;
    height: 40px !important;
    color: white;
    font-size: 10px !important;
    border-radius: 5px;
    background-color: black;
  }

  .button:hover {
    color: black;
    background-color: white;
    font-weight: 700;
  }

  .recentwebinar {
    background-size: cover;
    min-height: 30vh;
    width: 100%;

    margin-bottom: 50%;
  }

  .recentwebinar {
    padding-left: 30%;
    padding-right: 30%;
  }

  .h1classh1as {
    font-size: 30px;
    letter-spacing: 15px;
    text-align: center;

    font-weight: 900;
    color: #6a686a;
  }

  .classh2classas a {
    letter-spacing: 1px;
    text-align: center;
    color: white;
    background-color: cornflowerblue;
    font-size: 16px;
  }
}

/* ===================contact ============================================== */
.backz {
  background-image: url(/static/media/conpic.857aabd1.jpg);

  background-size: cover;
  max-height: 130vh;
  margin-bottom: 0%;
  margin-top: -11%;
  padding-bottom: 5%;
}

.gridnamez {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.aasz {
  margin-left: 5px;
}

bodyz {
  width: 100%;
}

#labelz {
  color: black;
}

.butuonz {
  margin-left: 30%;
}

.asdfghz {
  height: 50px;
  width: 50px;
}

#captchaz {
  height: 30px;
  width: 30px;

  margin: 20px;
}

.butedz {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  margin-left: 15%;
  margin-bottom: 30px;
  margin-right: 15%;
  background-color: rgba(95, 158, 160, 0.356);
}

.aaaz {
  color: black;
}

.h1asz {
  color: white;
  text-align: center;
}

.butz {
  margin-bottom: 30px;
}

.buttonz {
  margin-left: 4%;
  width: 200px;
  height: 50px;
  color: white;
  font-size: 25px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgba(0, 153, 213, 0.356),
    rgba(53, 229, 176, 0.336)
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hoverz {
  color: black;
  background: linear-gradient(
    to right,
    rgba(0, 153, 213, 0.356),
    rgba(53, 229, 176, 0.336)
  );

  font-weight: 700;
}

.aaa::-webkit-input-placeholderz {
  color: white;
}

.aaa:-ms-input-placeholderz {
  color: white;
}

.aaa::-ms-input-placeholderz {
  color: white;
}

.aaa::placeholderz {
  color: white;
}

.aaaz {
  flex: 1 1;
  padding: 18px 40px;
  font-size: 16px;
  color: white;
  background: transparent;
  border: none;
  border-bottom: white solid 2px;
  font-weight: 700;
  outline: none;
  padding-left: 5px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.containerz {
  background-color: rgba(0, 0, 0, 0.207);

  width: 40%;
  margin-top: 13%;
  margin-bottom: 20%;
  padding-bottom: 20px;
  padding-top: 100px;
}

@media (min-width: 600px) and (max-width: 1026px) {
  .backz {
    background: linear-gradient(
      to bottom,
      rgba(0, 153, 213, 0.356),
      rgba(53, 229, 176, 0.336)
    );
    margin-bottom: -100px;
  }
  .containerz {
    background-image: transparent;
    margin-top: 15%;
    width: 90%;

    padding-bottom: 50px;
    padding-top: 50px;
  }

  .backz {
    background-size: cover;
    min-height: 40vh;
    margin-bottom: 100px;

    width: 100%;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .backz {
    background: linear-gradient(
      to bottom,
      rgba(0, 153, 213, 0.356),
      rgba(53, 229, 176, 0.336)
    );
    margin-bottom: -100px;
  }

  .containerz {
    margin-top: 20%;
    width: 90%;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .butuonz {
    margin-left: 15%;
  }

  .butedz {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin-left: 10%;
    margin-bottom: 30px;
    margin-right: 10%;
    background-color: rgba(95, 158, 160, 0.356);
  }
  .imgsource {
    margin-top: -360px;

    width: 100%;
    height: 160vh;
    margin-bottom: -480px;
  }
}

.logos {
  margin: 0 0 0 30px !important;
  width: 60px !important;
  height: 60px;
}

.containereded {
  padding-left: 21%;
  padding-right: 21%;
}
.clasname {
  font-size: 14px;
}
.reactshare {
  display: block;
}
.navabar-logo {
  height: 550px;
  width: 70px;
  margin-right: -100px;
  position: relative;
  top: 20px;
}
.nameAndLogov3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

/* ========================================================================blogs ==================================================================== */
.threeblogDiv {
  display: flex;
  justify-content: space-around;
}
.navIcon {
  margin-right: 100px !important;
  position: absolute;
  right: 0;
}
.featured-image {
  width: 60%;
  max-height: 400px;
  object-fit: cover;
}
.v3card2 {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 350px;
  height: 620px;
  background-color: #fff;
  border-radius: 5px;
}

.v3card {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 350px;
  height: 720px;
  background-color: #fff;
}
.v3image {
  width: 350px;
  height: 208px;
}
.v3blogbody {
  padding: 30px;
}
.read {
  color: #00a8c6;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 800;
  text-decoration: none !important;
}
.headv3blog {
  padding-top: 100px;
  padding-bottom: 100px;
  color: #fff;
  font-weight: 400;
  font-size: 70px;
  text-align: center;
}
.v3blogexcerpt {
  font-size: 14px;
  color: #50565e;
  overflow: hidden;
}
.slugbody {
  font-size: 18px;
  color: #50565e;
}
.slugtitle {
  font-size: 60px;
  color: #1c2733;
  text-transform: capitalize;
}
.slugimage {
  width: 100%;
  height: 80vh;
}
.spanscript {
  color: #00a8c6;
  font-size: 10px;
}
.v3blogmain {
  background-image: linear-gradient(90deg, #00a8c6 0%, #0f697b 100%);
  padding-top: 100px;
}
.v3blog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v3blog-tilte {
  margin-top: 30px;
  font-size: 23px;
  color: #1c2733;
  font-weight: 300;
}
.contactContainer {
  background-image: url(/static/media/contact.784e5d3c.png);
  background-position: top;
  background-size: cover;
  height: 90vh;
  width: 100%;
}
.contactContainer h1 {
  position: relative;
  top: 40vh;
  color: #fff;
  margin-left: 50px;
}
.contactContainer h5 {
  position: relative;
  top: 40vh;
  color: #fff;
  margin-left: 50px;
}
.contactDetails {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  margin-top: 100px;
}
.imagebehindformKV {
  background: linear-gradient(
      to right,
      rgba(245, 45, 102, 0.7),
      rgba(247, 56, 31, 0.1)
    ),
    url(/static/media/sss.8c2de1e6.jpg);
  height: 80vh;
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: cover;
}

.formMainGridContactKV {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 80vh;
  width: 88vw;
  position: relative;
  top: -130px;
  left: 5%;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(26, 24, 24, 0.2);
}

.formheadingContactKV {
  color: #ff6041;
  padding-top: 150px;
  padding-left: 100px;
  padding-right: 60px;
  font-size: 18px;
}

.formheadingh1ContactKV {
  padding-left: 100px;
  font-size: 45px;
  padding-bottom: 60px;
}

.inputofFormContact {
  margin-left: 100px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  border: 1px solid #818a91;
  flex: 1 1;
  vertical-align: center;
  width: 450px;
  height: 47px;
  color: grey;
}

.inputofFormContact:focus {
  outline-color: #ff6041;
}

.inputofFormContact::-webkit-input-placeholder {
  padding: 6px 16px;
}

.inputofFormContact:-ms-input-placeholder {
  padding: 6px 16px;
}

.inputofFormContact::-ms-input-placeholder {
  padding: 6px 16px;
}

.inputofFormContact::placeholder {
  padding: 6px 16px;
}

.textareaContact {
  margin-left: 100px;
  padding-left: 16px;
  padding-top: 10px;
  color: grey;
  border: 1px solid #818a91;
}

.textareaContact:focus {
  outline-color: #ff6041;
}

.butonOfFormContactKV {
  margin-left: 100px;
  margin-top: 30px;
  background-color: #ff6041;
  color: #fff;
  width: 200px;
  height: 48px;
  border: none;
}

.butonOfFormContactKV:focus {
  outline: none;
}

.socialMediaContactKV {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.iconForLastContainerContact {
  padding-left: 25px;
  font-size: 25px;
}

.textForLastContainer {
  font-size: 25px;
  font-weight: 500;
  text-align: right;
}

.butonOfFormContactKV:focus {
  outline: none;
}

.socialMediaContactKV {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.iconfbliIn {
  margin-left: 10px;
  margin-right: 10px;
}

#fbicon {
  color: #1d119c;
}

#linicon {
  color: #0e76a8;
}

#insicon {
  color: rgb(221, 42, 123);
}

.onOfFormContactKV:focus {
  outline: none;
}

.socialMediaContactKV {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 25vh;
}

.officeMapContact {
  width: 90%;
  height: 60vh;
  margin-top: 12%;
}

.textareaContact {
  width: 450px;
  border-radius: 0;
}
.caseMain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}
.caseCard {
  max-width: 500px;
  height: 400px;
  background-color: #fff;
  box-shadow: 2px 0 50px #aaa;
  margin-top: 100px;
}
.webinarGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
  margin-top: 100px;
  margin-bottom: 100px;
}
.speakerCard {
  height: 300px;
  width: 250px;
  margin-bottom: 200px;
}
.speakerPic {
  width: 250px;
  height: 250px;
  border-radius: 6px;
  box-shadow: 2px 2px 6px #52b5ff, -2px -2px 6px #52b5ff;
}
.speakerName {
  width: 250px;
  min-height: 50px;
  background-color: #29abe2;
  border-radius: 6px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.formName {
  width: 550px;
  height: 50px;
  background-color: #29abe2;
  border-radius: 6px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;
  padding-left: 10px;
}
.speakerNamE {
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.speakerNAmE {
  font-size: 20px;
  color: #fff;
}
.formCard {
  min-height: 400px;
  width: 550px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.formContentweb {
  width: 100%;
  min-height: 300px;
  border-radius: 6px;
  box-shadow: 2px 2px 6px #52b5ff, -2px -2px 6px #52b5ff;
}
.speakGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  gap: 50px;
}
.inputFont {
  height: 50px;
  width: 100%;
  background-color: #fcfcfc;
  outline: none;
  border: none;
  margin-top: 20px;
}

.inputFont::-webkit-input-placeholder {
  color: #cccccc;
}

.inputFont:-ms-input-placeholder {
  color: #cccccc;
}

.inputFont::-ms-input-placeholder {
  color: #cccccc;
}

.inputFont::placeholder {
  color: #cccccc;
}
.select select {
  height: 50px;
  width: 100%;
  background-color: #fcfcfc;
  outline: none;
  border: none;
  margin-top: 20px;
}
.widthImage {
  height: 250px;
}
.impossibleNumber {
  font-size: 120px;
  color: #4d4d4d;
  font-weight: 900;
}
.blockHeght {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #4d4d4d;
  position: relative;
  top: 35px;
}
.gridForWebinar {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 2fr;
}
.backGrond {
  background-color: #f2f2f2;
  min-height: 50vh;
  width: 100%;
  padding-bottom: 50px;
}
.achorStyle {
  color: #fff;
}
.achorStyle:hover {
  color: #0093dc;
}
.webinarList{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 996px) {
  .deskView {
    display: none;
  }
  .gridForWebinar {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
  }
  .webinarGrid {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px;
  }
  .caseMain {
    grid-template-columns: 1fr;
  }
  .imagebehindformKV {
    background: linear-gradient(
        to right,
        rgba(245, 45, 102, 0.7),
        rgba(247, 56, 31, 0.1)
      ),
      url(/static/media/sss.8c2de1e6.jpg);
    height: 50vh;
    margin-top: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
  }
  .officeMapContact {
    width: 100%;
    height: 50vh;
    margin-top: 12%;
  }
  .navIcon {
    display: none;
  }
  .threeblogDiv {
    display: grid;
    justify-content: center;
  }
  .contactDetails {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    margin-top: 100px;
  }
  .formMainGridContactKV {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;
    width: 0;
    width: 100%;
    position: -webkit-sticky;
    left: 0;
    box-shadow: 10px 10px 10px rgba(26, 24, 24, 0.3);
  }
  .formheadingContactKV {
    padding-left: 0px;
    padding-right: 0px;
  }

  .formheadingh1ContactKV {
    padding-left: 0px;
  }

  .inputofFormContact {
    margin-left: 0px;
  }
  .textareaContact {
    margin-left: 0;
    padding-left: 0;
  }
  .formCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .formCard {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .conttitle {
    font-family: "Libre Baskerville", serif;
    font-size: 40px;
    font-weight: 500;
  }
  .featured-image {
    width: 95%;
    max-height: 400px;
    object-fit: cover;
  }
.webinarList{
  display: grid;
  grid-template-columns: 1fr;
}
}
@media screen and (max-width: 600px) {
  .containereded {
    padding-left: 1%;
    padding-right: 1%;
  }
  .widthImage {
    height: 250px;
    object-fit: cover;
    object-position: center;
  }
  .formCard {
    min-height: 400px;
    width: 100%;
  }
  .formName {
    width: 400px;
  }
  .speakGrid {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    grid-gap: 0px;
    gap: 0px;
  }
  .contactContainer h1 {
    left: 5px;
  }
  .contactContainer h5 {
    left: 5px;
  }
  .inputofFormContact {
    margin-left: 0px;
    width: 350px;
  }
  .textareaContact {
    width: 350px;
  }
  .contactContainer h1 {
    margin-left: 5px;
  }
  .contactContainer h5 {
    margin-left: 5px;
  }
  .webinarList{
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 400px) {
  .formCard {
    min-height: 400px;
    width: 350px;
    margin-left: 12px;
  }
  .formName {
    width: 350px;
  }
  .webinarList{
    display: grid;
    grid-template-columns: 1fr;
  }
}
